@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  min-width: 80vw;
  @include tablet {
    min-width: $modal-desktop-min-width;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $tf-grey-darker;
  position: relative;
}

.error {
  text-align: center;
  padding: $modal-padding-v 0;
}

.createEntity {
  font-size: $tf-description-font-size;
  color: $tf-description-default-color;

  .click {
    @include owardLink();
  }
}

.mediumModalHeader {
  width: 100%;
  text-align: center;
  padding-bottom: $tf-padding;
  font-size: 1.2rem;
}

$avatar-size: 4rem;

:export {
  avatarSize: $avatar-size;
}

.profileRectangleContainer {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;
  border: thin solid $tf-grey-light;
  border-radius: $tf-card-border-radius;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    border: thin solid $tf-grey-mid;
  }

  .profileContentContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-right: 1rem;

    .avatarContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      .avatar {
        width: $avatar-size;
        height: $avatar-size;
        object-fit: cover;
        border-radius: calc(#{$avatar-size} / 2);
      }
    }

    .nameJobContainer {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .name {
        font-family: $tf-font-title;
        font-weight: $tf-bold;
        font-size: 1.3rem;

        @include tablet() {
          font-size: 1.5rem;
        }
      }

      .job {
        font-size: 1rem;

        @include tablet() {
          font-size: 1.2rem;
        }
      }
    }

    .countContainer {
      flex: 10;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }
}
