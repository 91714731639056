@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.introTextContainer {
  @include tablet {
    max-width: $modal-desktop-min-width;
  }

  text-align: center;

  .text {
    white-space: pre-wrap;
    font-size: 1rem;
  }
}

.headerMessage {
  font-weight: $tf-semibold;
}

.tab {
  padding-left: 1rem;
}

.subscriptionTitle {
  font-weight: $tf-book;
}

.chooseTypeMainContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .profileTypeCardMainContainer {
    //width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: $tf-border;
    border-radius: $tf-border-radius;
    cursor: pointer;
    padding: $tf-padding;
    margin: calc(#{$tf-padding} / 2);

    &:hover {
      border-color: $tf-border-color-hover;
    }

    .iconContainer {
      padding-right: $tf-padding;
      font-size: 1.5rem;
    }

    .textContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-weight: $tf-semibold;
        font-size: 1.3rem;
      }

      .desc {
        @include owardDescription();
      }
    }
  }
}
