@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.dropdownsContainer {
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 0.3rem;

  .dropdownContainer {
    padding-bottom: 0.5rem;
    scroll-margin: 1rem;

    .title {
      font-size: $tf-sidebar-accodrion-dropdwon-title-font-size;
      font-weight: $tf-semibold;
    }
  }
}

.categoryIntro {
  padding-bottom: 1rem;
}

.explanation {
  font-size: $tf-sidebar-festival-switch-explanation-font-size;
  color: $tf-grey-mid;
}
