@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.adminMenuContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .adminMenuButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border: $tf-border;
    border-radius: $tf-card-border-radius;
    cursor: pointer;
    padding: 0.5rem;
    margin: calc(#{0.5rem} / 2);
    @include desktop() {
      padding: $tf-padding;
      margin: calc(#{$tf-padding} / 2);
    }

    &:hover {
      border-color: $tf-border-color-hover;
    }

    .iconContainer {
      padding-right: 1rem;
      font-size: 1.2rem;
      @include desktop() {
        font-size: 1.5rem;
        padding-right: $tf-padding;
      }
    }

    .nameContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .name {
        font-weight: $tf-semibold;
        font-size: 1.1rem;

        @include desktop() {
          font-size: 1.3rem;
        }
      }

      .desc {
        @include owardDescription();
      }
    }
  }
}
