@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.selectorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: $tf-padding;
}

.noProfileContainer {
  width: 100%;
  padding: $tf-padding;
  text-align: center;
  white-space: pre-wrap;
}

.notLoggedContainer {
  width: 100%;
  padding: $tf-padding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  white-space: pre-wrap;

  .buttonContainer {
    padding: $tf-padding;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
