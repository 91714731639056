@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/mixins.sass";

.searchContainer {
  .searchInput {
    @include owardInput();

    height: $tf-sidebar-accordion-button-height;
  }

  .searchIconContainer {
    height: $tf-sidebar-accordion-button-height !important;
    position: relative;

    .icon {
      fill: $tf-grey-dark;
      position: absolute;
      width: 50%;
      height: 50%;
      left: 35%;
      top: 25%;
    }
  }
}

.input {
  @include owardInput();
}

.textarea {
  padding: 0.5rem 1rem;
  resize: none;
  border-radius: $tf-card-border-radius;
}

.errorContainer {
  padding-top: 0.2rem;

  .error {
    color: $tf-red;
  }
}

.passwordRevealButton {
  border-radius: 2rem;
  pointer-events: initial !important; // see https://github.com/jgthms/bulma/issues/1965

  &:hover {
    cursor: pointer;
    background-color: $tf-grey-lighter;
  }

  .passwordRevealIcon {
    color: $tf-grey-dark !important;
  }
}
