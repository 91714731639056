@import "@assets/sass/bulma-customization.scss";

:export {
  greyDarker: $tf-grey-darker;
  greyDark: $tf-grey-dark;
  greyMid: $tf-grey-mid;
  greyMidLight: $tf-grey-mid-light;
  greyLight: $tf-grey-light;
  blueLogo: $tf-blue-logo;
  blue: $tf-blue;
  blue30: $tf-blue-30;
  blue50: $tf-blue-50;
  semiBold: $tf-semibold;
  book: $tf-book;
  dropdownFontSize: $tf-sidebar-accodrion-dropdwon-font-size;
  dropdownGroupFontSize: $tf-sidebar-accodrion-dropdwon-group-font-size;
  headerFiltersMenuWidth: $tf-header-filters-select-menu-width;
  borderRadius: $tf-border-radius;
}

.label {
  font-weight: $tf-semibold;
}

.input {
  width: 100%;
  background: transparent;
  &::placeholder {
    color: $tf-grey-mid;
  }
  color: $tf-grey-darker;
  border: 1px solid;
  border-color: $tf-grey-light;
  border-radius: $tf-border-radius;
  outline: none;
  padding: 0 1rem;

  &:hover {
    border-color: $tf-grey-mid;
  }
}

.errorContainer {
  .error {
    color: $tf-red;
  }
}
