@import "../../../../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/generic.sass";
@import "~bulma/sass/utilities/initial-variables.sass";

.headerContainer {
  display: flex;
  flex-direction: column;

  .filtersMainContainer {
    padding: $tf-padding $tf-padding 0 $tf-padding;

    .filtersContainer {
      padding-bottom: $tf-padding;
      display: flex;
      align-items: center;

      .filterContainer {
        flex: 1;
        padding: 0.5rem;
      }
    }

    .hr {
      margin: 0;
    }
  }

  .inputAndLoadContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.tableLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .sortMainContainer {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;

    .sortContainer {
      cursor: pointer;
      color: $tf-grey-mid;

      &:hover {
        color: $tf-grey-darker;
      }
    }
  }
}
