@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.contentContainer {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: $modal-padding-v 0;
  color: $tf-grey-darker;

  .explanation {
    max-width: 500px;
    text-align: center;
    padding-bottom: $modal-padding-v;
  }

  .error {
    text-align: center;
    padding: $modal-padding-v 0;
    color: $tf-red;
  }

  .privateContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .labelContainer {
      flex: 1;
    }

    .switchContainer {
      flex: 1;
      justify-content: flex-end;
    }
  }

  .button {
    outline: none;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: $tf-blue;
    border-radius: $tf-border-radius;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: $tf-blue-logo;
    }

    text-align: center;
    color: white;
    font-weight: $tf-semibold;
  }
}
