@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 5rem;

:export {
  avatarSize: $avatar-size;
}

.profileContainer {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  border: thin solid $tf-grey-light;
  border-radius: $tf-card-border-radius;

  &:hover {
    border: thin solid $tf-grey-mid;
  }

  .profileTopContainer {
    .profileLeftContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      @include tablet() {
        justify-content: flex-start;
      }
      padding-right: 1rem;

      .avatarContainer {
        //flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: $avatar-size;
          height: $avatar-size;
          object-fit: cover;
          border-radius: calc(#{$avatar-size} / 2);
        }
      }

      .nameJobContainer {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;

        .name {
          font-family: $tf-font-title;
          font-weight: $tf-bold;
          font-size: 1.5rem;

          @include tablet() {
            font-size: 2rem;
          }
        }

        .job {
          font-size: 1rem;

          @include tablet() {
            font-size: 1.4rem;
          }
        }
      }
    }

    .profileRightContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include tablet() {
        align-items: flex-start;
      }
      padding-top: 1rem;
      padding-right: 0;
      @include tablet() {
        padding-top: 0;
        padding-right: 1rem;
      }

      .state {
        color: $tf-red;
      }
    }
  }

  .profileBottomContainer {
    width: 100%;
    padding-top: 1rem;
    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @include desktop() {
      justify-content: center;
      align-items: center;
    }

    .profileStatusLine {
      margin-top: 0.5rem;
      @include desktop() {
        margin-top: 0rem;
      }
      display: flex;
      justify-content: center;
      align-items: center;

      .iconContainer {
        padding-right: 0.5rem;

        .iconSuccess {
          color: $tf-green;
        }

        .iconError {
          color: $tf-red;
        }
      }

      .textContainer {
        font-weight: $tf-semibold;
      }
    }
  }

  .modifyIconContainerDesktop {
    $modify-padding: 1rem;
    position: absolute;
    right: 1rem;
    top: calc(50% - 2 *#{$modify-padding});
    display: none;
    @include tablet() {
      display: flex;
    }
  }

  .modifyIconContainerMobile {
    padding-top: 1rem;
    display: none;
    @include mobile() {
      display: flex;
    }
    justify-content: center;
    align-items: center;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionDetailsContainer {
  .red {
    color: $tf-red;
  }

  .link {
    @include owardLink();
  }
}
