@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.button {
  outline: none;
  margin: 0.5rem;
  padding: 0.5rem 0.8rem;
  @include desktop {
    padding: 1rem;
  }
  background-color: $tf-blue;
  border-radius: $tf-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $tf-blue-logo;
  }

  .contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // On Safari only, this is needed for content to be centered in buttons. Don't ask me why.
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        height: 1px;
      }
    }
  }

  .fontawesomeIcon {
    @include desktop {
      padding: 0;
    }
    padding: 0 1rem;
  }

  .name {
    text-align: center;
    color: $tf-white;
    font-weight: $tf-semibold;
  }

  .svgIconContainer {
    width: $tf-header-buttons-icon-width-mobile;
    height: $tf-header-buttons-icon-width-mobile;
    position: relative;

    @include desktop {
      width: $tf-header-buttons-icon-width;
      height: $tf-header-buttons-icon-width;
    }

    .svgIcon {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .svgIconContainerWithName {
    // Margin between icon and name (if there's a name)
    margin-right: $tf-header-buttons-icon-margin-r;
  }
}

.svgIconOnly {
  padding: 1rem 0.8rem;

  @include desktop {
    padding: 1.5rem 1rem;
  }
}

.bigPadding {
  padding: 0.5rem 1rem;
  @include desktop {
    padding: $tf-padding;
  }
}

.noMargin {
  margin: 0;
}

.notCentered {
  justify-content: flex-start;
}

.fullWidth {
  width: 100%;
  margin: 0.5rem 0;
}

.outlined {
  border: thin solid;
  border-color: $tf-grey-darker;
  background-color: transparent;

  .name {
    color: $tf-grey-darker;
  }

  &:hover {
    border-color: $tf-blue;
    background-color: transparent;
  }
}

.outlinedDark {
  border: thin solid;
  border-color: $tf-white;
  background-color: transparent;

  .name {
    color: $tf-white;
  }

  .svgIcon {
    fill: $tf-white;
  }

  &:hover {
    border-color: $tf-blue;
    background-color: transparent;
  }
}

.red {
  background-color: $tf-red;

  .svgIcon {
    fill: $tf-white;
  }

  &:hover {
    background-color: $tf-red-dark;
  }
}

.white {
  background-color: $tf-white;
  //border: thin solid transparent;
  border: thin solid $tf-grey-light;
  //box-shadow: $tf-card-box-shadow;

  .name {
    color: $tf-grey-darker;
    font-weight: $tf-regular;
  }

  .svgIcon {
    fill: $tf-grey-darker;
  }

  &:hover {
    border: thin solid $tf-grey-mid;
    background-color: $tf-white;
  }
}

.transparent {
  background-color: transparent;
  //border: thin solid transparent;
  border: thin solid $tf-grey-light;
  //box-shadow: $tf-card-box-shadow;

  .name {
    color: $tf-grey-darker;
    font-weight: $tf-regular;
  }

  .svgIcon {
    fill: $tf-grey-darker;
  }

  &:hover {
    border: thin solid $tf-grey-mid;
    background-color: transparent;
  }
}

.transparentBlue {
  background-color: transparent;
  //border: thin solid transparent;
  border: thin solid $tf-blue;
  //box-shadow: $tf-card-box-shadow;

  .name {
    color: $tf-blue;
    font-weight: $tf-regular;
  }

  .svgIcon {
    fill: $tf-blue;
  }

  &:hover {
    border: thin solid $tf-blue-logo;
    background-color: transparent;

    .name {
      color: $tf-blue-logo;
    }
    .svgIcon {
      fill: $tf-blue-logo;
    }
  }
}

.transparentBlueActive {
  background-color: $tf-blue;
  //border: thin solid transparent;
  border: thin solid $tf-blue;
  //box-shadow: $tf-card-box-shadow;

  .name {
    color: $tf-white;
    font-weight: $tf-regular;
  }

  .svgIcon {
    fill: $tf-white;
  }

  &:hover {
    border: thin solid $tf-blue-logo;
    background-color: $tf-blue-logo;
  }
}

.deactivated {
  cursor: default;
  border: thin solid $tf-grey-light;
  background-color: transparent;

  .name {
    color: $tf-placeholder-color;
  }

  &:hover {
    border: thin solid $tf-grey-light;
    background-color: transparent;
    .name {
      color: $tf-placeholder-color;
    }
  }
}

.menuButtonContainer {
  height: $tf-sidebar-accordion-button-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $tf-grey-lighter;
  border-radius: $tf-border-radius;
  border: thin solid $tf-grey-light;
  padding: $tf-sidebar-accordion-button-padding-v $tf-sidebar-accordion-button-padding-h;
  margin: $tf-sidebar-accordion-button-margin-v 0;

  &:hover {
    //background-color: $tf-grey-light;
    border: thin solid $tf-grey-mid;
    cursor: pointer;
  }

  .nameIconContainer {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 10;
    justify-content: flex-start;
    align-self: center;

    .iconContainer {
      flex: 1;
      height: 100%;
      min-width: calc(#{$tf-sidebar-accordion-button-height} - #{$tf-sidebar-accordion-button-padding-h});
      position: relative;

      .icon {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    .name {
      flex: 30;
      padding-left: $tf-sidebar-accordion-button-name-padding-l;
      font-size: $tf-sidebar-accordion-button-name-font-size;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .nameActive {
      font-weight: $tf-semibold;
    }
  }

  .angleContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: center;

    .angle {
      transition: transform $tf-sidebar-accordion-angle-transition-params;
    }
    .angleRotated {
      transform: rotate(90deg);
    }
  }
}

.owardLink {
  @include owardLink();
}
