@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.popUpOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  .popUpContainer {
    z-index: 201;
    max-height: 75vh;
    @include desktop {
      max-height: 90vh;
    }
    width: 90vw;
    max-width: 600px;
    padding: $modal-padding-v $modal-padding-mobile-h;
    @include desktop {
      width: 100%;
      padding: $modal-padding-v $modal-padding-h;
    }
    background-color: white;
    border-radius: $modal-border-radius;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-overflow-scrolling: touch;
    text-align: center;

    .title {
      font-weight: $tf-semibold;
      font-size: 1.2rem;
      @include desktop {
        font-size: 1.4rem;
      }
      padding-bottom: $modal-padding-v;
    }

    .text {
      font-size: 1rem;
      @include desktop {
        font-size: 1.2rem;
      }
      font-weight: $tf-semibold;
      white-space: pre-wrap;
    }

    .textInformation {
      font-size: 1rem;
      white-space: pre-wrap;
    }

    .buttonContainer {
      padding-top: $modal-padding-v;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
  }
}
