@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

.subtitle {
  padding-top: 0.5rem;
}

@include mobile {
  html {
    .header {
      padding-bottom: 16px;
    }

    .is-centered-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
