@import "@assets/sass/bulma-customization.scss";

.labelContainer {
  // Don't put padding here but margin.
  // Somehow, it create a weird behavior on mobile. When trying to scroing, on mobile, "through" a label,
  // with "scroll into view", it only works once
  margin: 0.3rem 0;

  .label {
    font-weight: $tf-semibold;
  }

  .description {
    font-size: $tf-description-font-size;
    white-space: pre-wrap;
  }

  .descriptionColorDefault {
    color: $tf-description-default-color;
  }
}
.center {
  text-align: center;
}
