@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 1.5rem;

:export {
  avatarSize: $avatar-size;
}

.messagesMainContainer {
  position: relative;
  padding: $tf-padding;
  flex: 100;
  width: 100%;
  height: 100%;
  overflow: auto;

  .messagesContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: 100%;
    width: 100%;
    flex-direction: column;

    .messageScrollingContainer {
      position: relative;

      .messageScrolling {
        position: absolute;
        // Top offset for scrolling on loading new messages
        // ~loader + date container size
        top: -110px;
      }
    }

    .dateLineContainer {
      width: 100%;
      padding: 0.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .date {
        font-size: 0.8rem;
        color: $tf-grey-mid;
        border: solid thin $tf-grey-light;
        border-radius: $tf-border-radius;
        padding: 0.2rem 1rem;
      }

      .line {
        height: 1px;
        width: 100%;
        background-color: $tf-grey-light;
      }
    }

    .seenAtContainer {
      width: 100%;
      padding-top: 0.5rem;
      display: flex;
      justify-content: flex-end;

      .seenAt {
        font-size: 0.8rem;
        color: $tf-grey-mid;
      }
    }

    .messageGroupMainContainer {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      white-space: pre-wrap;

      .senderContainer {
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;

        .senderText {
          flex: 100;
          padding-left: 0.5rem;

          .senderName {
            font-size: 1rem;
            font-weight: $tf-semibold;
          }

          .senderName.clickable {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }

          .time {
            padding-left: 0.5rem;
            font-size: 0.8rem;
            color: $tf-grey-mid;
          }
        }
      }
    }

    .messageGroupMineMainContainer {
      width: 100%;
      text-align: end;
      white-space: pre-wrap;
      align-items: flex-end;
    }

    $message-margin-bottom: 0.2rem;

    .messageGroupContainer {
      display: flex;
      flex-direction: column;
      width: 80%;

      @include tablet {
        width: 60%;
      }

      .messageMainContainer {
        display: flex;
        justify-content: flex-start;
        height: 100%;

        $m-br-big: 1rem;
        $m-br-small: 0.4rem;

        .message {
          text-align: start;
          width: fit-content;
          padding: $message-margin-bottom 1rem;
          margin-bottom: $message-margin-bottom;
          background-color: $tf-grey-lighter;
          border-radius: $m-br-small;

          @include touch {
            &:active {
              background-color: $tf-grey-light;
            }
          }

          .link {
            @include owardLink();
          }
        }

        .message.mine {
          border-radius: $m-br-big $m-br-small $m-br-small $m-br-big;
        }

        .message.mine.isFirst {
          border-radius: $m-br-big $m-br-big $m-br-small $m-br-big;
        }

        .message.mine.isLast {
          border-radius: $m-br-big $m-br-small $m-br-big $m-br-big;
        }

        .message.mine.alone {
          border-radius: $m-br-big;
        }

        .message.notMine {
          border-radius: $m-br-small $m-br-big $m-br-big $m-br-small;
        }

        .message.notMine.isFirst {
          border-radius: $m-br-big $m-br-big $m-br-big $m-br-small;
        }

        .message.notMine.isLast {
          border-radius: $m-br-small $m-br-big $m-br-big $m-br-big;
        }

        .message.notMine.alone {
          border-radius: $m-br-big;
        }

        .pictureContainer {
          padding: 0.4rem;
          margin-bottom: $message-margin-bottom;
        }

        .picture {
          cursor: pointer;
          border-radius: calc(#{$tf-border-radius} / 2);
        }
      }

      .messageMineMainContainer {
        justify-content: flex-end;
      }
    }
  }

  .noMessageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .noMessage {
      color: $tf-grey-mid-light;
    }
  }
}

.reportButtonContainer {
  flex: 1;
  display: flex;

  @include touch {
    display: none;
  }
  width: 100%;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: flex-start;
  color: transparent;
  &:hover {
    color: $tf-grey-mid-dark;
  }

  .iconContainer {
    padding: 0.3rem;
    border-radius: $tf-border-radius;
    cursor: pointer;
    &:hover {
      background-color: $tf-grey-light-max;
    }
  }
}

.reportButtonContainer.isMine {
  justify-content: flex-end;
}
