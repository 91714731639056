@import "@assets/sass/bulma-customization.scss";

.mainContainer {
  padding: 1rem 0;

  .headerMessageContainer {
    text-align: center;

    .headerMessage {
      font-weight: $tf-semibold;
      font-size: 1.3rem;
    }
  }
}
