@import "@assets/sass/bulma-customization.scss";

.label {
  font-weight: $tf-semibold;
}

.input {
  @include owardInput();
}

.inputAndClear {
  display: flex;
  align-items: center;

  .inputContainer {
    flex: 1;
  }

  .clearContainer {
    flex-basis: 0;
  }
}

.errorContainer {
  .error {
    color: $tf-red;
  }
}

.selectContainer {
  padding: 0 0.5rem;

  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;

    height: auto;
    border: thin solid $tf-border-color;
    margin: 0;
    padding: 0.5rem;
    border-radius: $tf-border-radius;
    cursor: pointer;

    &:hover {
      border: thin solid $tf-border-color-hover;
    }
  }
}
