@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$sidebar-width: 12rem;
$sidebar-mobile-width: 3rem;

@include tablet {
  html {
    .sidebar {
      padding-top: 16px;
      width: $sidebar-width;
    }
  }

  .container {
    padding-left: $sidebar-width;
  }
}

@include mobile {
  html {
    .sidebar {
      width: $sidebar-mobile-width;
    }
  }

  .container {
    padding-left: $sidebar-mobile-width;
  }

  .category {
    display: none;
  }
}

.sidebar {
  display: block;
  position: fixed;
  left: 0;
  z-index: 1;
  height: calc(100vh - #{$tf-navbar-height});
  overflow: scroll;
}

.sidebar {
  border-right: thin solid;
  border-color: rgba(0, 0, 0, 0.2);
  background-color: white;
}
