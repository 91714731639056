@import '../../assets/sass/bulma-customization.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

$tf-cookie-panel-padding-v: 0.5rem;

.title {
  padding-top: $tf-cookie-panel-padding-v;
  text-align: center;
  font-size: 2rem;
  font-weight: $tf-semibold;
  color: $tf-grey-darker;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }
}

.footer {
  text-align: center;
  padding-top: 1rem;
  font-size: 0.8rem;
}

.contentContainer {
  padding: $modal-padding-v 0;
  color: $tf-grey-darker;

  .subtitle {
    font-weight: $tf-semibold;
    font-size: 1.5rem;
    padding-bottom: $tf-cookie-panel-padding-v;
  }

  .description {
    padding-bottom: $tf-cookie-panel-padding-v;
  }

  .example {
    font-weight: $tf-semibold;
  }

  .switchContainer {
    padding: $tf-cookie-panel-padding-v 0;
  }

  .partnersList{
    padding: $tf-cookie-panel-padding-v 0;
  }

  .partnerSwitchContainer {
    padding: 0.2rem 0;
  }
}

