@import "../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/generic.sass";
@import "~bulma/sass/utilities/initial-variables.sass";

:export {
  navbarHeight: $navbar-height;
}

.navBar {
  transition: top 0.4s; /* Transition effect when sliding down (and up) */
  top: 0;
  //box-shadow: $tf-layout-shadow;
}

.mainContainer {
  // The viewport is scaled down on mobile, see
  // https://stackoverflow.com/questions/30358630/html-body-not-filling-complete-width-on-mobile-devices
  // &
  // https://stackoverflow.com/questions/14270084/overflow-xhidden-doesnt-prevent-content-from-overflowing-in-mobile-browsers/14271049#14271049
  overflow-x: hidden;
  padding-top: $navbar-height;
}

@mixin icon {
  font-size: 1rem;
  color: $tf-white;
  padding: 0.5rem;
  border-radius: $tf-border-radius;
}

@mixin navbarLink {
  font-weight: $tf-book;
  color: $tf-white;
  padding: 0.5rem;
  border-radius: $tf-border-radius;
  cursor: pointer;

  &:hover {
    background-color: $tf-grey-dark;
  }
}

@mixin brandName {
  .brandName {
    font-weight: $tf-bold;
    font-size: 1.5rem;
    color: $tf-white;

    .noLetterSpacing {
      letter-spacing: -0.45rem;
      color: $tf-white;
    }
  }
}

.borderRadiusContainer {
  border-radius: $tf-border-radius;
  margin: 0.3rem 0.2rem;
}

//Navbar Desktop version
.desktopContentContainer {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;

  .leftContainer {
    flex: 10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
  }

  .centerContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include brandName();
  }

  .rightContainer {
    flex: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .dropdownContainer {
    padding: 0.25rem;
  }

  .loginIconDropdown {
    @include icon();
    padding: 0.5rem 0.8rem;
    margin: 0 0.2rem;
    border-radius: $tf-border-radius;
    cursor: pointer;
    &:hover {
      background-color: $tf-grey-dark;
    }
  }

  .navbarLink {
    @include navbarLink();
    font-size: 0.85rem;
    margin: 0.2rem 0.25rem;

    .goldIcon {
      padding-left: 0.3rem;
      color: $tf-gold;
    }

    .whiteIcon {
      padding-left: 0.3rem;
      color: $tf-white;
    }
  }

  .navbarLinkActive {
    font-weight: $tf-semibold;
    border: thin solid $tf-gold;
  }

  .icon {
    @include icon();
    margin: 0 0.2rem;
    position: relative;
  }
}

//Navbar Mobile version
.mobileContainerBrandName {
  @include brandName();
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.mobileContentContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 1rem;

  .navbarLink {
    @include navbarLink();
    font-size: 1rem;
    margin: 0.25rem 0;
    padding-left: 0.9rem;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .navbarLinkActive {
    font-weight: $tf-semibold;
    border: thin solid $tf-gold;
  }

  .navbarUserIsLogged {
    font-family: $tf-font-title;
    font-weight: $tf-bold;
    color: $tf-gold;

    &:hover {
      cursor: default;
      background-color: transparent;
      color: $tf-gold;
    }
  }
}

.svgIconContainer {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: $tf-border-radius;

  .svgIcon {
    position: absolute;
    font-size: 0.9rem;
    padding: 0.7rem 0.7rem;
    border-radius: $tf-border-radius;

    &:hover {
      background-color: $tf-grey-dark;
    }
  }
}

.svgAndNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $tf-border-radius;
  height: 1.5rem;

  .svgIconContainer {
    position: relative;

    // Very ugly...!
    margin-left: -1rem;
    height: 2.5rem;
    width: 2.5rem;

    .svgIcon {
      position: absolute;
      font-size: 0.9rem;
      padding-right: 0.7rem;
      border-radius: $tf-border-radius;
    }
  }
}

.icon {
  @include icon();
  padding-right: 1.5rem;
}

.searchButtonName {
  color: $tf-white;
  font-weight: $tf-semibold;

  .emoji {
    margin-right: 0.5rem;
  }
}
