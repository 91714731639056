@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.closeButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .closeButton {
    &:hover {
      cursor: pointer;
      color: $tf-grey-light;
    }
  }
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .iconContainer {
    flex: 1;
    padding-right: 1rem;

    .iconSuccess {
      color: $tf-green;
    }

    .iconError {
      color: $tf-red;
    }
  }

  .textContainer {
    flex: 10;
  }
}
