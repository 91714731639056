@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalOverlayDark {
  background-color: rgba(0, 0, 0, 0.8);
}

.modalOverlayFixedSize {
  display: block;
}

$modal-max-width: 1000px;

.modal {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  @include desktop {
    max-height: 90vh;
  }
  max-width: 95vw;
  padding: 0;
  //padding: $modal-padding-v $modal-padding-mobile-h $modal-padding-bottom $modal-padding-mobile-h;
  @include desktop {
    padding: $modal-padding-v $modal-padding-h $modal-padding-bottom $modal-padding-h;
  }
  background-color: white;
  border-radius: $modal-border-radius;
  border: none;
  outline: none;
}

.modalDark {
  background-color: $tf-grey-darker;
}

.modalFixedSize {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 100vh;
  max-width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  padding: 0;
  @include desktop {
    top: 3rem;
    bottom: 3rem;
    width: $modal-max-width;
    border-radius: 1rem;
    left: calc(50% - (#{$modal-max-width} / 2));
    padding: $modal-padding-v $modal-padding-h $modal-padding-bottom $modal-padding-h;
  }
}

.modalNoPadding {
  padding: 0;
}

.closeIconContainer {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 101;

  @include desktop {
    top: 0;
    right: 1rem;
  }

  .closeIcon {
    color: $tf-white;
    font-size: 1.5rem;
    @include desktop {
      font-size: 2rem;
    }

    &:hover {
      cursor: pointer;
      color: $tf-grey-light;
    }
  }

  .closeIconDark {
    color: $tf-grey-dark;

    @include desktop {
      color: $tf-white;
    }

    &:hover {
      color: $tf-grey-darker;

      @include desktop {
        color: $tf-grey-light;
      }
    }
  }
}

.headerContainer {
  flex: 1;
  padding: 0.8rem;
  text-align: center;
  @include desktop {
    padding: 0;
    padding-bottom: $modal-padding-v;
  }
}

.title {
  text-align: center;
  font-size: 1.1rem;
  font-weight: $tf-semibold;

  @include desktop {
    font-size: 1.5rem;
  }
}

.subtitle {
  text-align: center;
  font-weight: $tf-light;
  white-space: pre-wrap;
  font-size: 0.8rem;
  @include desktop {
    font-size: 1rem;
  }
}

.contentContainer {
  flex: 100;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @include desktop {
    border-radius: 1rem;
  }
}

.contentContainerPadding {
  padding: 1rem;
}

.footerContainer {
  flex: 1;
  @include desktop {
    padding-top: $modal-padding-bottom;
  }
}

.hrHeaderFooter {
  margin: 0;

  @include desktop {
    margin: 0 calc(-#{$modal-padding-h});
  }
}

.buttonsAndErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .error {
    color: $tf-red;
    text-align: center;
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
