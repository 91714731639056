@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  display: flex;
  border: $tf-border;
  border-radius: $tf-card-border-radius;
  margin: $tf-sidebar-accordion-button-margin-v 0;

  padding: 0.75rem;

  .contentContainer {
    flex: 100;
    .title {
      font-weight: $tf-semibold;
      font-size: 1rem;
      @include desktop {
        font-size: 1.1rem;
      }
    }

    .textContainer {
      white-space: pre-wrap;
    }
  }

  .chevronContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
}

.mainContainer.clickable {
  cursor: pointer;
}

.mainContainer.yellow {
  color: $tf-yellow-dark-font;
  background-color: $tf-yellow-background;
}

.mainContainer.green {
  color: $tf-green-dark-font;
  background-color: $tf-green-background;
}

.mainContainer.blue {
  color: $tf-white;
  background-color: $tf-blue;
}

.mainContainer.blueOutlined {
  border-color: $tf-blue-50;
  color: $tf-blue;
}

.mainContainer.yellow.clickable {
  &:hover {
    border-color: $tf-grey-mid;
  }
}

.mainContainer.green.clickable {
  &:hover {
    border-color: $tf-grey-mid;
  }
}

.mainContainer.blue.clickable {
  &:hover {
    background-color: $tf-blue-logo;
  }
}

.mainContainer.blueOutlined.clickable {
  &:hover {
    border-color: $tf-blue-logo;
  }
}
