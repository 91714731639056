@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$logo-size: 4rem;
$logo-size-mobile: 3rem;

.mainContainer {
  width: 100%;
  background-color: $tf-grey-lighter;

  /* Padding helper : top | right | bottom | left */
  padding: 1rem 1rem 0.875rem 1rem;
  @include tablet {
    padding: 1rem 1.25rem 0 1.25rem;
  }
  @include desktop {
    padding: 1rem 2.25rem 0 2.25rem;
  }
  @include widescreen {
    padding: 1rem 2.25rem 0 2.25rem;
  }
  @include fullhd {
    padding: 1rem 3rem 0 2.25rem;
  }

  display: flex;
  flex-direction: column;
}

.topContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titlesContainer {
    text-align: center;
    margin: $tf-padding 0;
    color: $tf-grey-darker;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      font-weight: $tf-bold;
      font-size: $tf-header-title-size-mobile;

      @include desktop {
        font-size: $tf-header-title-size;
      }

      @include fullhd {
        font-size: $tf-header-title-size-wide;
      }
    }

    .longTitle {
      font-size: calc($tf-header-title-size-mobile * 0.8);

      @include desktop {
        font-size: calc($tf-header-title-size * 0.8);
      }

      @include fullhd {
        font-size: calc($tf-header-title-size-wide * 0.8);
      }
    }

    .veryLongTitle {
      font-size: calc($tf-header-title-size-mobile * 0.7);

      @include desktop {
        font-size: calc($tf-header-title-size * 0.7);
      }

      @include fullhd {
        font-size: calc($tf-header-title-size-wide * 0.7);
      }
    }
  }

  .discoverPhraseContainer {
    @include desktop {
      width: 80%;
    }

    @include fullhd {
      width: 60%;
    }

    .discoverPhrase {
      color: $tf-grey-mid-dark;
      white-space: pre-wrap;
      font-weight: $tf-book;
      font-size: 1.1rem;
      @include desktop {
        font-size: 1.3rem;
      }

      .link {
        @include owardLink();
      }
    }
  }

  .logosContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .logoContainer {
      width: $logo-size-mobile;
      height: $logo-size-mobile;
      margin: 0.8rem $tf-padding;
      @include desktop {
        width: $logo-size;
        height: $logo-size;
        margin: 0.8rem 3rem;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .knowMoreContainer {
    width: 100%;
    padding-bottom: 0.5rem;
    @include touch() {
      padding-top: 0.5rem;
    }
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .showHide {
      @include owardLink();
    }

    .text {
      width: 100%;
      font-size: 1rem;
      @include desktop {
        font-size: 1.1rem;
        width: 80%;
      }
      @include fullhd {
        font-size: 1.2rem;
        width: 60%;
      }
      padding: 1rem 0;
      white-space: pre-wrap;
      font-weight: $tf-semibold;

      .link {
        @include owardLink();
      }
    }
  }

  .subtitleMainContainer {
    display: flex;
    flex-direction: center;
    align-items: center;
    max-width: calc(3 * #{$tf-card-width} + 2 * #{$tf-card-gap});
    margin-bottom: $tf-padding;

    .subtitleContainer {
      background-color: $tf-white;
      border-radius: $tf-border-radius;
      border: thin solid $tf-grey-light;

      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: start;

      padding: 1rem;

      @include desktop {
        padding: 1.5rem 2rem;
      }

      .link {
        @include owardLink();
      }

      .semibold {
        font-weight: $tf-semibold;
      }

      .closeContainer {
        margin-top: $tf-padding;
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }
  }

  .gallerySelectorContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: $tf-padding 0;

    .text {
      padding-bottom: 0.5rem;
      font-weight: $tf-book;
    }
  }
}

.filtersMainContainer {
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: $tf-header-filters-container-padding 0;

  .title {
    font-weight: $tf-bold;
    font-size: $tf-header-h1-size-mobile;

    @include desktop {
      font-size: $tf-header-h1-size;
    }

    @include widescreen {
      font-size: $tf-header-h1-size-wide;
    }
  }

  .filtersContainer {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0.3rem 0;

    @include tablet {
      // Horizontal padding for dropdowns menu not to be cropped ont the right side
      padding: 0.5rem calc(#{$tf-header-filters-select-menu-width} - #{$tf-header-filters-select-width});
      flex-direction: row;
    }

    .filterContainer {
      padding: 0.1rem;
      width: 100%;
      @include tablet {
        padding: 0.3rem;
        width: 13rem;
      }
      @include widescreen {
        padding: 0.5rem;
      }
    }
  }

  .moreFilters {
    @include owardLink();
  }

  .titleAndButtonContainer {
    position: relative;
    display: inline-block;

    .shareButtonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: white;
      border: thin solid $tf-grey-light;
      border-radius: $tf-border-radius;

      &:hover {
        cursor: pointer;
        border: thin solid $tf-grey-mid;
      }

      right: calc((-#{$tf-header-h1-size-mobile} * 2) - #{$tf-header-h1-size-mobile});
      top: calc(-#{$tf-header-h1-size-mobile}/ 4);
      height: calc(#{$tf-header-h1-size-mobile} * 2);
      width: calc(#{$tf-header-h1-size-mobile} * 2);

      @include desktop {
        right: calc((-#{$tf-header-h1-size} * 2) - #{$tf-header-h1-size});
        top: calc(-#{$tf-header-h1-size}/ 4);
        height: calc(#{$tf-header-h1-size} * 2);
        width: calc(#{$tf-header-h1-size} * 2);
      }

      @include widescreen {
        right: calc((-#{$tf-header-h1-size-wide} * 2) - #{$tf-header-h1-size-wide});
        top: calc(-#{$tf-header-h1-size-wide}/ 4);
        height: calc(#{$tf-header-h1-size-wide} * 2);
        width: calc(#{$tf-header-h1-size-wide} * 2);
      }

      .shareButton {
        font-size: 0.8rem;

        @include desktop {
          font-size: 1rem;
        }

        @include widescreen {
          font-size: 1.2rem;
        }
      }
    }
  }

  .activeFiltersContainer {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0.5rem 0;

    @include tablet {
      flex-direction: row;
    }

    .search {
      font-weight: $tf-semibold;
    }

    .activeFilterContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $tf-grey-darker;
      border-radius: $tf-header-filter-border-radius;
      font-size: $tf-header-filters-font-size-mobile;
      margin: $tf-header-filter-margin-mobile;
      padding: $tf-header-filter-padding-v-mobile $tf-header-filter-padding-r-mobile $tf-header-filter-padding-v-mobile
        $tf-header-filter-padding-l-mobile;

      @include desktop {
        font-size: $tf-header-filters-font-size;

        margin: $tf-header-filter-margin;
        padding: $tf-header-filter-padding-v $tf-header-filter-padding-r $tf-header-filter-padding-v
          $tf-header-filter-padding-l;
      }

      @include widescreen {
        margin: $tf-header-filter-margin-wide;
      }

      .filterName {
        color: $tf-white;
      }

      .filterIconContainer {
        padding-left: $tf-header-filter-icon-padding-l-mobile;

        @include desktop {
          padding-left: $tf-header-filter-icon-padding-l;
        }

        .filterIcon {
          color: $tf-white;

          &:hover {
            cursor: pointer;
            color: $tf-grey-light;
          }
        }
      }
    }
  }
}

.emphasisedFiltersMainContainer {
  // On mobile, don't show this emphasised filter block
  display: none;
  @include tablet {
    display: flex;
  }
}

.activeFiltersMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: $tf-header-filters-container-padding;

  .activeFiltersContainer {
    .search {
      font-weight: $tf-semibold;
    }

    .activeFilterContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $tf-grey-dark;
      border-radius: $tf-header-filter-border-radius;
      font-size: $tf-header-filters-font-size-mobile;
      margin: $tf-header-filter-margin-mobile;
      padding: $tf-header-filter-padding-v-mobile $tf-header-filter-padding-r-mobile $tf-header-filter-padding-v-mobile
        $tf-header-filter-padding-l-mobile;

      @include desktop {
        font-size: $tf-header-filters-font-size;

        margin: $tf-header-filter-margin;
        padding: $tf-header-filter-padding-v $tf-header-filter-padding-r $tf-header-filter-padding-v
          $tf-header-filter-padding-l;
      }

      .filterName {
        color: $tf-white;
      }

      .filterIconContainer {
        padding-left: $tf-header-filter-icon-padding-l-mobile;

        @include desktop {
          padding-left: $tf-header-filter-icon-padding-l;
        }

        .filterIcon {
          color: $tf-white;

          &:hover {
            cursor: pointer;
            color: $tf-grey-light;
          }
        }
      }
    }
  }
}

.noFilterSpace {
  height: $tf-padding;
}

.buttonsMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .buttonsTopContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include tablet() {
        flex-direction: row;
      }
    }

    .orderButtonContainer {
      width: auto;
    }
  }
}

.zoomContainer {
  display: flex;
  width: 12rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;

  @include desktop {
    padding: 0.7rem 1rem;
    @supports (-moz-appearance: none) {
      // In Firefox the horizontal padding is reduce... weird
      padding: 0.7rem 3rem;
    }
  }

  border: thin solid $tf-grey-light;
  border-radius: $tf-border-radius;
  background-color: "transparent";

  .zoomText {
    padding-right: $tf-header-buttons-icon-margin-r;
    outline: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standar */
  }
}
