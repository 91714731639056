@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
}

.contactDetailsContainer {
  padding: $tf-padding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: $tf-book;

  .noPhone {
    font-size: 1rem;
    color: $tf-grey-mid;
    font-style: italic;
    font-weight: $tf-regular;
  }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: $tf-book;
  color: $tf-red;
}
