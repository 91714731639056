@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.subtitleContainer {
  @include tablet {
    max-width: $modal-desktop-min-width;
  }
}

.mainContainer {
  min-width: 80vw;
  @include tablet {
    min-width: $modal-desktop-min-width;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $tf-grey-darker;
  position: relative;

  .hr {
    margin: 0 0;
  }

  .listItemContainer {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: $tf-grey-lighter;
    }

    .listItemText {
      flex: 10;

      .privateIcon {
        padding-left: 1rem;
      }
    }

    .semibold {
      font-weight: $tf-semibold;
    }

    .checkboxContainer {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      $checkbox-size: 1.7rem;
      .checkbox {
        justify-content: flex-end;
        align-items: center;
        height: $checkbox-size;
        width: $checkbox-size;
        border: 1px solid;
        border-radius: $checkbox-size;

        &:hover {
          border-color: $tf-blue;
        }
      }

      .checkboxActive {
        background-color: $tf-blue;
        border-color: $tf-blue;

        &:hover {
          background-color: $tf-blue-logo;
          border-color: $tf-blue-logo;
        }
      }
    }
  }
}

.error {
  text-align: center;
  padding: $modal-padding-v 0;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.shortlistLink {
  @include toasterLink();
}
