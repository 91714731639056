@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.goToGalleryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
