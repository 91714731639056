@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$page-margin-mobile: 1rem;
$page-margin-desktop: 2rem;
$page-margin-fullhd: 3rem;

.mainCenteredContainer {
  margin-top: $page-margin-mobile;
  height: calc(100vh - #{$navbar-height} - #{$page-margin-mobile});
  @include desktop {
    margin-top: $page-margin-desktop;
    height: calc(100vh - #{$navbar-height} - #{$page-margin-desktop});
  }
  @include fullhd {
    margin-top: $page-margin-fullhd;
    height: calc(100vh - #{$navbar-height} - #{$page-margin-fullhd});
  }
}

.mainCenteredContainerVerticallyCentered {
  display: flex;
  justify-content: center;
  text-align: center;
}

.columnsContainer {
  max-height: 100%;
  @include tablet {
    height: 100%;
  }
}
.contentContainer {
  padding: $page-margin-mobile;
  @include desktop {
    padding: $page-margin-desktop;
  }
  @include fullhd {
    padding: $page-margin-fullhd;
  }
}

.centered {
  display: flex;
  justify-content: center;
}

.noPadding {
  padding: 0;
}

.editProfileMainContainer {
  position: relative;
  height: calc(100vh - #{$navbar-height});

  .errorContainer {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.imageOnLeftMainContainer {
  width: 100%;
  display: flex;
  height: calc(100vh - #{$navbar-height});

  .leftContainer {
    flex: 1;
    height: 100%;
    display: none;
    @include desktop {
      display: block;
    }
  }

  .rightContainer {
    flex: 1;
    max-height: 100%;
    overflow: auto;
    padding: calc(#{$tf-padding});
    @include desktop {
      padding: calc(#{$tf-padding} * 2);
    }

    .rightContentContainer {
      max-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
