@import "@assets/sass/bulma-customization.scss";

.mainContainer {
  width: 100%;
  position: relative;

  .noShortlistText {
    font-size: 0.9rem;
    color: $tf-grey-darker;
  }

  .lineContainer {
    display: flex;
    padding: 0.2rem 0;

    .shortlistNameContainer {
      cursor: pointer;
      display: flex;
      flex: 20;
      align-items: center;
      padding: 0.5rem;
      border-radius: $tf-border-radius;

      .privateIcon {
        padding-left: 0.5rem;
      }
      &:hover {
        background-color: $tf-grey-lighter;
      }
    }

    .shortlistNameContainerActive {
      color: white;
      background-color: $tf-blue;
      p {
        font-weight: $tf-semibold;
      }

      &:hover {
        background-color: $tf-blue-logo;
      }
    }

    .modifyContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.1rem 0 0.1rem 0.5rem;

      .modifyIconContainer {
        padding: 0.5rem;
        border-radius: $tf-border-radius;
        border: thin solid transparent;

        &:hover {
          background-color: $tf-grey-lighter;
          //border: thin solid $tf-grey-mid;
          cursor: pointer;
        }
      }
    }
  }

  .hr {
    margin: 0 0;
  }
}
