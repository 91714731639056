@import '../../assets/sass/bulma-customization.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

.mainContainer {
  position: fixed;
  z-index: 50;
  height: 100%;
  width: 100%;

  .shadowContainer {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $tf-grey-darker;
    opacity: 0.4;
  }

  .firstPanel {
    position: fixed;
    bottom: 0;
    min-height: 150px;
    width: 100%;
    background-color: $tf-grey-darker;
    display: flex;
    justify-content: center;
    align-items: center;

    .firstPanelContainer {
      max-height: 100vh;
      overflow: auto;
      width: 1300px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
        @include desktop {
          flex-direction: row;
        }
      justify-content: center;
      align-items: center;
      color: white;

      .firstPanelTextContainer {
        flex: 3;

        .firstPanelTextTitle {
          font-family: $tf-font-title;
          font-size: 1.5rem;
        }

        .firstPanelText {
          font-size: 0.9rem;
        }
      }

      .firstPanelButtonContainer {
        flex: 3;
        @include widescreen {
          flex: 2;
        }
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        @include desktop {
          flex-direction: row;
        }
      }
    }
  }
}
