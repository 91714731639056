@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  display: flex;
  flex-direction: row;

  .labelContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 10;

    .label {
      font-size: $tf-sidebar-accodrion-dropdwon-title-font-size;
      font-weight: $tf-semibold;
    }
  }
}

.formSwitchContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .labelAndSwitchContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .labelContainer {
      flex: 100;
      font-weight: $tf-semibold;
    }
  }
}

.switchContainer {
  padding-left: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.errorContainer {
  padding-top: -0.2rem;

  .error {
    color: $tf-red;
  }
}

$multipleOptionContainerMargin: 0.2rem;

.multipleMainContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: $tf-border-radius;
  border: thin solid $tf-blue;
  color: $tf-grey-darker;

  .optionContainer {
    padding: 0.5rem 1rem;
    margin: $multipleOptionContainerMargin;
    border-radius: $tf-border-radius;
    font-weight: $tf-book;
    text-align: center;
  }

  .optionActive {
    background-color: $tf-blue;
    color: $tf-white;
    cursor: default;
  }

  .optionNoActive {
    cursor: pointer;

    &:hover {
      background-color: $tf-blue-30;
    }
  }
}

.multipleMainContainerBlack {
  border: thin solid $tf-grey-darker;

  .optionActive {
    background-color: $tf-grey-darker;
  }

  .optionNoActive {
    &:hover {
      background-color: $tf-grey-light;
    }
  }
}

.multipleMainContainerStackOnMobile {
  @include mobile {
    padding: 0 $multipleOptionContainerMargin;
    flex-direction: column;
    border-radius: calc($tf-border-radius / 2);

    .optionContainer {
      width: 100%;
      border-radius: calc($tf-border-radius / 2);
    }
  }
}

.multipleFlatMainContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $tf-grey-mid;
  font-size: 1.2rem;
  @include desktop {
    font-size: 1.5rem;
  }

  .optionContainer {
    padding: 0.5rem;
    font-weight: $tf-book;
    text-align: center;

    .labelBis {
      font-size: 0.8rem;
      @include desktop {
        font-size: 1rem;
      }
    }
  }

  .optionActive {
    font-weight: $tf-bold;
    color: $tf-grey-darker;
    border-bottom: thick solid $tf-grey-darker;
    cursor: default;
  }

  .optionNoActive {
    cursor: pointer;
    font-weight: $tf-semibold;
    color: $tf-grey-mid;
    border-bottom: thin solid $tf-grey-darker;

    &:hover {
      color: $tf-grey-dark;
    }
  }
}

.multipleFlatMainContainer.small {
  font-size: 1rem;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: calc(2 * (#{$switch-height} - #{$switch-margin}));
  height: $switch-height;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: $switch-transition;
  transition: $switch-transition;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(#{$switch-height} - 2 * #{$switch-margin});
  width: calc(#{$switch-height} - 2 * #{$switch-margin});
  left: $switch-margin;
  bottom: $switch-margin;
  background-color: white;
  -webkit-transition: $switch-transition;
  transition: $switch-transition;
}

input:checked + .slider {
  background-color: $switch-active-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $switch-active-color;
}

input:checked + .slider.blue {
  background-color: $tf-blue;
}

input:focus + .slider.blue {
  box-shadow: 0 0 1px $tf-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(#{$switch-height} - 2 * #{$switch-margin}));
  -ms-transform: translateX(calc(#{$switch-height} - 2 * #{$switch-margin}));
  transform: translateX(calc(#{$switch-height} - 2 * #{$switch-margin}));
}

/* Rounded sliders */
.slider.round {
  border-radius: $switch-height;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.dark {
  background-color: $tf-grey-mid;
}
