@import "../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

:export {
  avatarSize: $tf-card-avatar-size;
}

// All deactivated stuff are not clickable, and not underlined
.deactivated {
  cursor: default !important;
  text-decoration: none !important;
}

.mainContainer {
  position: relative; // Useful for block container
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; // On mobile, there's no fixed height
  padding: $tf-card-padding-v 0;
  @include from-card-not-100vw {
    border: thin solid $tf-grey-light;
  }
  transition: box-shadow $tf-card-shadow-transition-params;
  background-color: $tf-white;

  &:hover {
    box-shadow: $tf-card-box-shadow-hover;
  }

  @include from-card-not-100vw {
    width: $tf-card-width;
    height: $tf-card-height;
    padding: $tf-card-padding-v $tf-card-padding-h;

    border-radius: $tf-card-border-radius;
  }

  transform-origin: top left; // To be sure scalling cards keep them in good position
}

.contentContainer {
  flex-direction: column;
  width: 100%;
  height: 100%;

  .headerContainer {
    height: $tf-card-header-height;
    width: 100%;
    display: flex;
    flex-direction: row;

    .locationContainer {
      flex: 5;
      display: flex;
      max-height: $tf-card-header-height;
      overflow: hidden;
      justify-content: flex-start;
      text-align: left;
      padding-left: $tf-card-header-padding;
      padding-right: $tf-card-header-padding;
      @include from-card-not-100vw {
        padding-left: 0;
      }

      .location {
        color: $tf-grey-mid;
        font-weight: $tf-regular;
        font-size: $tf-card-header-text-size;
      }
    }

    .updateContainer {
      flex: 5;
      display: flex;
      max-height: $tf-card-header-height;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: right;
      padding-right: $tf-card-header-padding;
      padding-left: $tf-card-header-padding;
      @include from-card-not-100vw {
        padding-right: 0;
      }

      .update {
        cursor: default; // Tooltip make a cursor pointer : we don't want that
        color: $tf-gold;
        font-weight: $tf-semibold;
        font-size: $tf-card-header-text-size;
      }
    }
  }

  .subHeaderContainer {
    height: auto;
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $tf-card-subheader-padding-t $tf-card-subheader-padding-mobile-h $tf-card-subheader-padding-b
      $tf-card-subheader-padding-mobile-h;
    @include from-card-not-100vw {
      padding: $tf-card-subheader-padding-t 0 $tf-card-subheader-padding-b 0;
    }
    text-align: center;
    line-height: $tf-card-subheader-line-height;

    .nameAndBadgeContainer {
      max-width: 100%;
      position: relative;
      display: inline-block;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $tf-grey-darker;
        font-family: $family-secondary;
        font-size: $tf-card-name-text-size;
        font-weight: $tf-bold;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .badgeCheckIconContainer {
        height: 15px;
        width: 15px;
        top: 4px;
        right: -20px;
        position: absolute;

        .icon {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }

      .badgeIsNotExposedIconContainer {
        height: 30px;
        width: 30px;
        top: -4px;
        left: -35px;
        position: absolute;

        .icon {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }

    .jobAsso {
      margin-top: $tf-card-subheader-job-margin-top;

      // On Safari only, no need to margin-top. (Why ? That's a very good question.)
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          margin-top: 0;
        }
      }

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: $tf-semibold;
      font-size: $tf-card-job-text-size;

      .job {
        color: $tf-grey-darker;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .bioContainer {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: $tf-card-bio-padding-v $tf-card-bio-padding-h;
    @include from-card-not-100vw {
      height: $tf-card-bio-height; // When not on mobile, constraint the height of the bio container
      padding: $tf-card-bio-padding-v 0;
    }
  }

  @mixin bio {
    color: $tf-grey-mid-dark;
    font-size: $tf-card-bio-text-size;
    line-height: $tf-card-bio-line-height;
    font-weight: $tf-regular;
  }

  // Not in bio container as it's also for text in bioHiddenContainer
  .bio {
    @include bio();
  }

  .bioLong {
    @include bio();
    white-space: pre-wrap;
  }

  .knowMore {
    font-weight: $tf-semibold;
    white-space: nowrap;
    color: $tf-grey-dark;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .slideContainer {
    height: auto;
    width: 100%;
    @include from-card-not-100vw {
      // Be sure to not have small borders when opening long bio on desktop
      padding: 0 1px 0 1px;
    }
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .slideHiddenContainer {
      z-index: 5; // Stay on top
      width: 100%;
      top: -1px; // Linked to issue https://github.com/DimitriTuaz/oward/issues/178
      left: 0;
      $height-of-artwork-desc: calc(
        (#{$tf-card-artwork-type-text-size} + #{$tf-card-artwork-title-text-size}) +
          (#{$tf-card-artwork-info-line-height} - 1rem) + #{$tf-card-artwork-info-pagging-t} + #{$tf-card-artwork-info-pagging-b}
      );
      height: calc(
        #{$tf-card-artwork-large-ratio} * 100vw + #{$height-of-artwork-desc} + #{$tf-card-buttons-padding-h}
      );
      @include from-card-not-100vw {
        height: calc(#{$tf-card-artwork-large-h} + #{$height-of-artwork-desc} + #{$tf-card-buttons-padding-h});
      }
      position: absolute;
      transform: translateY(-100%);
      transition: transform $tf-card-bio-hidden-transition-params;
      text-align: center;
      justify-content: center;
      background: $tf-white;

      .bioHiddenContainer {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: $tf-card-bio-hidden-padding-t $tf-card-bio-padding-h $tf-card-bio-hidden-padding-b
          $tf-card-bio-padding-h;

        @include from-card-not-100vw {
          padding: $tf-card-bio-hidden-padding-t 0 $tf-card-bio-hidden-padding-b 0;
        }
      }
    }
  }

  .artworksContainer {
    width: 100%;
    flex: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

// Not in content container as it's used in ProfilePersoPage also
.bioExternalLink {
  @include owardLink();
}

.bioLink {
  color: $tf-blue;

  &:hover {
    color: $tf-blue;
    cursor: pointer;
    text-decoration: underline;
  }
}

.avatarContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $tf-card-avatar-size;
  height: $tf-card-avatar-size;

  @mixin avatarCommon {
    width: $tf-card-avatar-size;
    height: $tf-card-avatar-size;
    cursor: pointer;
    border-radius: calc(#{$tf-card-avatar-size} / 2);

    transition: border-radius $tf-card-artwork-desc-transition-params !important;
    &:hover {
      border-radius: 0.5rem;
    }
  }

  .greyBorder {
    border: $tf-border;
  }

  .goldenBorder {
    border: $tf-border-gold;
  }

  .avatar {
    @include avatarCommon();
    object-fit: cover;
  }

  .avatar.deactivated {
    border-radius: calc(#{$tf-card-avatar-size} / 2);
  }

  .initialsContainer {
    @include avatarCommon();
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $tf-grey-mid;

    .initials {
      overflow: hidden;
      font-family: $tf-font-title;
      font-weight: $tf-bold;
      font-size: 1.2rem;
      text-overflow: ellipsis;
      color: $tf-white;
    }
  }

  .initialsContainer.deactivated {
    border-radius: calc(#{$tf-card-avatar-size} / 2);
  }
}

.toasterLink {
  @include toasterLink();
}

.blockMainContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.92;
  background-color: $tf-grey-darker;
  z-index: 7; // Stay on top

  @include from-card-not-100vw {
    border-radius: $tf-card-border-radius;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      color: $tf-grey-light;
      text-align: center;
    }
  }
}
