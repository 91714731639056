@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.avatarContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar {
    object-fit: cover;
  }

  .initialsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $tf-grey-mid;

    .initials {
      overflow: hidden;
      font-family: $tf-font-title;
      font-weight: $tf-bold;
      font-size: 0.6rem;
      text-overflow: ellipsis;
      color: $tf-white;
    }
  }

  .clickable {
    cursor: pointer;
    transition: border-radius $tf-card-artwork-desc-transition-params !important;
    &:hover {
      border-radius: 0.5rem;
    }
  }
}

.size1_5rem {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: calc(1.5rem / 2);
}

.size2rem {
  width: 2rem;
  height: 2rem;
  border-radius: calc(2rem / 2);
}

.size3rem {
  width: 3rem;
  height: 3rem;
  border-radius: calc(3rem / 2);
}

.size4rem {
  width: 4rem;
  height: 4rem;
  border-radius: calc(4rem / 2);
}
