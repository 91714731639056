@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.embedContainer {
  text-align: center;
  float: left;

  .iframeVimeoContainer {
    //width: 90%;
    margin: 0 auto;
  }

  .iframeYoutubeContainer {
    position: relative;
    padding: 56.25% 0 0 0;

    .iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .iframeFacebookContainer {
    padding-bottom: 56.25%;
    position: relative;
    outline: none;
    height: 0;
  }

  .otherLinkContainer {
    color: white;
    background-image: url("https://cdn-oward-common-images.oward.co/mockup-tous-blur.png");
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19rem;

    .button {
      background-color: $tf-white;
      font-size: 1rem;
      font-weight: $tf-book;
      padding: 1.5rem 3rem;
      border-radius: $tf-card-border-radius;
      border: $tf-border;
      color: $tf-grey-dark;
      outline: none;

      @include mobile {
        margin: 2rem;
      }

      &:hover {
        cursor: pointer;
        border-color: $tf-grey-mid-dark;
      }
    }

    .textContainer {
      flex-direction: column;
      background-color: $tf-white;
      border: $tf-border;
      color: $tf-grey-dark;
      font-weight: $tf-book;
      border-radius: $tf-card-border-radius;
      margin: 1rem;
      padding: 1rem;
      font-size: 0.8rem;

      @include tablet {
        margin: 3rem;
      }

      @include desktop {
        padding: 2rem;
        margin: 10rem;
        font-size: 1rem;
      }

      .cookieIcon {
        font-size: $tf-header-title-size;
        color: $tf-gold;
      }

      .clickHere {
        @include owardLink();
      }
    }
  }

  .loaderContainer {
    color: white;
    width: 100%;
    z-index: 6;
    display: flex;
    padding: $artwork-modal-padding-h $artwork-modal-text-padding-mobile-h;
    @include desktop {
      padding: $artwork-modal-padding-h $artwork-modal-text-padding-h;
    }
    justify-content: center;
    align-items: center;
  }
}
