@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 3rem;

:export {
  blue: $tf-blue;
  borderRadius: $tf-border-radius;
  avatarSize: $avatar-size;
}

.link {
  @include owardLink();
}

.whiteLink {
  color: $tf-white;
  font-weight: $tf-semibold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.mainContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.createEntity {
  font-size: $tf-description-font-size;
  color: $tf-description-default-color;

  .click {
    @include owardLink();
  }
}

.previewMainContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $tf-grey-lighter;
  display: flex;
  justify-content: center;

  .previewContainer {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .previewCardContainer {
      min-height: $tf-card-height;
    }

    // For "save and cancel" buttons to have space
    margin-bottom: 4rem;
    @include desktop {
      margin-bottom: 0;
    }

    .explanation {
      padding-top: 0;
      @include desktop() {
        padding-top: $tf-padding;
      }
      padding-bottom: 0.5rem;
      text-align: center;
      font-weight: $tf-semibold;

      .link {
        @include owardLink();
        font-weight: $tf-book;
      }
    }
  }

  // For 'preview scroll' to take into account the navbar
  padding-top: calc(#{$tf-navbar-height} - #{$tf-padding} + 0.5rem);
  @include desktop {
    padding-top: 0;
  }
}

.contentContainer {
  height: 100%;
  padding: $tf-padding;
  padding-bottom: 2rem;
  overflow-y: scroll;
  position: relative;

  .headerContainer {
    .goBack {
      @include owardLink();
      font-size: 0.9rem;
    }
  }

  .blockContainer {
    width: 100%;

    margin-bottom: 1.5rem;
    @include desktop {
      margin-bottom: 2rem;
    }
  }

  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(#{$tf-padding} / 2);

    .title {
      font-family: $tf-font-title;
      font-weight: $tf-bold;
      font-size: 1.2rem;
      @include desktop {
        font-size: 1.3rem;
      }
    }
  }

  .subtitle {
    font-weight: $tf-semibold;
    font-size: 1.1rem;
    @include desktop {
      font-size: 1.2rem;
    }
  }

  .publishContainer {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.formMainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .formHeaderContainer {
    flex: 1;
  }

  .formContentContainer {
    flex: 100;
    position: "relative";

    .loadAvatarContainerContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .loadAvatarContainer {
        position: relative;
        width: 100%;
        max-width: 400px;

        // Padding for mobile to scroll
        padding: 0 2rem;
        @include desktop {
          padding: 0;
        }

        .loadAvatarPreviewContainer {
          position: relative;
          margin-top: 1rem;

          .loadAvatarDelete {
            position: absolute;
            right: -1rem;
            top: -1rem;
            color: $tf-grey-darker;
            cursor: pointer;

            &:hover {
              color: $tf-grey-dark;
            }
          }
        }
      }
    }

    .artworkCreateButtonContainer {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .deleteButtonContainer {
      width: 100%;
      text-align: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .promoCodeTitle {
      font-weight: $tf-semibold;
    }
    .promoCode {
      font-size: 1.2rem;
      font-weight: $tf-semibold;
    }
  }

  .formFooterContainer {
    flex: 1;
  }
}

.scrollMobileContainer {
  position: absolute;
  top: -$tf-navbar-height;
  width: 100%;
  height: $tf-navbar-height;
  @include desktop {
    height: 0;
  }
}

.saveOrCancelContainer {
  width: 100%;
  z-index: 6;
  position: fixed;
  bottom: 0;
  background-color: $tf-white;

  @include desktop {
    position: relative;
    margin-left: 0;
    border: none;
  }

  .footerHr {
    margin: 0;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .buttonContainer {
      flex: 10;
      padding: 0.2rem 0.5rem;
      @include desktop() {
        padding: 0.5rem 2rem;
      }
    }

    .previewButtonContainer {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      @include desktop() {
        display: none;
      }
    }
  }

  .errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 1rem;
    padding-bottom: 0.5rem;

    .error {
      color: $tf-red;
    }
  }
}

.description {
  font-size: $tf-description-font-size;
  white-space: pre-wrap;
  color: $tf-description-default-color;
  text-align: center;
}

.artworkSubtitle {
  text-align: center;
  font-size: 1.2rem;
  font-weight: $tf-book;
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
}

.moreThan3ArtworksSubtitle {
  text-align: center;
  font-size: 1.1rem;
  font-weight: $tf-semibold;
  color: $tf-red;
}

.artworkListContainer {
  position: relative;
  padding: 0.5rem 1rem;
  background-color: $tf-grey-lighter;
  border-radius: 0.5rem;
  border: thin solid $tf-grey-light;
  min-height: 4rem;

  .artworkContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $tf-white;
    border: thin solid $tf-grey-light;
    border-radius: 0.5rem;
    margin: 0.3rem 0;
    padding: 0.5rem;
    position: relative;

    &:hover {
      border: thin solid $tf-grey-mid;
      cursor: grab;
    }

    .descContainer {
      padding-bottom: 1.5rem;

      .desc {
        text-align: center;
      }
    }

    .avatarContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $avatar-size;
      height: $avatar-size;

      .avatar {
        width: $avatar-size;
        height: $avatar-size;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }

    .name {
      flex: 100;
      padding-left: 0.5rem;
    }

    .modifyIconContainer {
      flex: 1;
      border-radius: $tf-border-radius;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $tf-grey-lighter;
        //border: thin solid $tf-grey-mid;
        cursor: pointer;
      }
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .draggingProfileContainer {
    border: thin solid $tf-blue;
  }
}

.subscriptionContainer {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  .subscriptionsDetailsContainer {
    display: flex;
    flex-direction: column;
    flex: 3;
    align-items: center;
    justify-content: center;

    @include desktop {
      align-items: flex-start;
    }
  }

  .subscribeContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    text-align: center;

    @include desktop {
      justify-content: flex-end;
      padding-top: 0;
    }
  }
}

@mixin needPortfolioPosition {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: $tf-border-radius;
}
.needPortfolioOpacity {
  @include needPortfolioPosition();
  background-color: $tf-black;
  opacity: 0.75;
}

.needPortfolioContainer {
  @include needPortfolioPosition();

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  @include desktop {
    padding: 2rem 4rem;
  }
  @include widescreen {
    padding: 2rem 10rem;
  }

  .text {
    color: $tf-white;
    font-weight: $tf-semibold;
  }
}
