@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$saveOrCancelContainer: 0.2rem;

.description {
  @include owardDescription();
  white-space: pre-wrap;
}

.artworkCoverLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    flex: 10;
  }

  .customize {
    @include owardLink();
    flex: 1;
  }
}

.previewMainContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $tf-grey-lighter;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: calc(#{$saveOrCancelContainer} * 2 + #{$tf-padding} + 2rem);
  padding: $tf-padding 0;
  // For 'preview scroll' to take into account the navbar
  padding-top: calc(#{$tf-navbar-height} + 0.5rem);

  @include from-card-not-100vw {
    padding: $tf-padding;
    padding-top: calc(#{$tf-navbar-height});
  }

  .explanationContainer {
    width: 100%;
    text-align: center;

    .explanation {
      font-weight: $tf-semibold;
    }

    .ratio {
      @include owardDescription();
    }
  }

  .artworkContainer {
    height: auto;
    width: 100vw;
    padding: 0;
    background-color: white;
    margin-bottom: 2rem;
    @include from-card-not-100vw {
      width: $tf-card-width;
      padding: $tf-card-padding-h $tf-card-padding-h 0.5rem $tf-card-padding-h;
      border-radius: $tf-border-radius;
      border: thin solid $tf-border-color;
    }
  }
}

.uploadCoverContainer {
  position: relative;
  width: 100%;

  .uploadCoverHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;

    .nameContainer {
      flex: 1;

      .ratio {
        @include owardDescription();
      }
    }

    .buttonContainer {
      padding: 0 0.5rem;
    }
  }

  .uploadCoverPreviewContainerContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 3rem;
    border: thin solid $tf-border-color;
    border-radius: $tf-border-radius;

    // Padding for mobile to scroll
    padding: 0 2rem;
    @include desktop {
      padding: 0;
    }

    .uploadCoverDelete {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      color: $tf-grey-darker;
      cursor: pointer;

      &:hover {
        color: $tf-grey-dark;
      }
    }

    .uploadCoverPreviewContainer {
      max-width: 90%;
      max-width: 500px;
      margin: 1.5rem;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.selectionsContainer {
  width: 100%;

  .selectionContainer {
    padding: 0.5rem;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    border: thin solid $tf-border-color;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      border-color: $tf-border-color-hover;
    }

    .infoContainer {
      flex: 1000;

      .festival {
        font-weight: $tf-book;
        font-size: 0.8rem;

        @include desktop {
          font-size: 1rem;
        }
      }

      .award {
        color: $tf-grey-dark;
        font-size: 0.8rem;

        @include desktop {
          font-size: 0.9rem;
        }

        .trophyIcon {
          height: 0.8rem;
        }
      }
    }

    .modifyIconContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .modifyIcon {
        flex: 1;
        display: flex;
        padding: 0.5rem;
        border-radius: $tf-border-radius;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: $tf-grey-lighter;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.artworkPhotoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @include widescreen {
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .uploadButton {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
  }

  .mainContainerPhoto {
    flex: 1;
    width: 100%;

    .descContainer {
      text-align: center;
      padding: 1rem 0;

      .desc {
        @include owardDescription();
      }
    }

    //Droppable Css From Modify Shortlist

    .listContainer {
      padding: 0.5rem 1rem;
      background-color: $tf-grey-lighter;
      border-radius: 0.5rem;
      border: thin solid $tf-grey-light;
    }

    .draggablePhotoContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $tf-white;
      border: thin solid $tf-grey-light;
      border-radius: 0.5rem;
      margin: 0.3rem 0;
      padding: 0 0.5rem;

      &:hover {
        border: thin solid $tf-grey-mid;
        cursor: grab;
      }

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    .draggingPhotoContainer {
      border: thin solid $tf-blue;
    }

    .photosContainer {
      height: 100%;
      flex: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;

      .photo {
        display: flex;
        object-fit: cover;
        border-radius: 0.2rem;
        max-height: 6rem;
      }
    }

    .deleteButtonContainer {
      flex: 1;
      cursor: pointer;
    }
  }
}
