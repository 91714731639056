@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  position: relative;
  flex: 1;
  max-height: 100%;

  .alternativeTitle {
    text-align: center;
    font-weight: $tf-semibold;
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }

  .errorContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .error {
      color: $tf-red;
      text-align: center;
      white-space: pre-wrap;
    }
  }

  .linkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .link {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.imageContainer {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("https://cdn-oward-common-images.oward.co/login-page.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  .text {
    display: none;
    @include desktop {
      display: block;
    }
    padding: 0 2rem;
    color: $tf-white;
    font-weight: $tf-bold;
    font-size: 3rem;
  }

  .credits {
    position: absolute;
    padding-bottom: 0.5rem;
    color: $tf-white;
    bottom: 0;

    .link {
      color: $tf-white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
