/*
 * the filmMakers brand style & customization
 */

// Colors
$tf-black: #000000;
$tf-white: #ffffff;
$tf-grey-darker: #151515;
$tf-grey-dark: #333333;
$tf-grey-mid-dark: #555555;
$tf-grey-mid: #666666;
$tf-grey-mid-light: #aaaaaa;
$tf-grey-light: #e2e2e2;
$tf-grey-lighter: #eff0f3;
$tf-grey-light-max: #f8f8f8;
$tf-pink: #775175;
$tf-blue-logo: #355293;
$tf-blue: #0066cc;
$tf-blue-30: rgba($tf-blue, 0.3);
$tf-blue-50: rgba($tf-blue, 0.5);
$tf-gold-light: #ffe080;
$tf-gold: #ddb16b;
$tf-yellow-background: #fcf5e4;
$tf-yellow-dark-font: #725c00;
$tf-red: #e7002e;
$tf-red-dark: #cd002b;
$tf-green: #23cb00;
$tf-green-background: #e5fce5;
$tf-green-dark-font: #027200;

//Fonts
$tf-thin: 100;
$tf-light: 200;
$tf-regular: 400;
$tf-book: 500;
$tf-semibold: 600;
$tf-bold: 700;

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-Thin-WebXL.woff");
  font-weight: $tf-thin;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-Light-WebM.woff");
  font-weight: $tf-light;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-Regular-WebM.woff");
  font-weight: $tf-regular;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-RegularItalic-WebM.woff");
  font-weight: $tf-regular;
  font-style: italic;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-Book-WebM.woff");
  font-weight: $tf-book;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-SemiBold-WebM.woff");
  font-weight: $tf-semibold;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-Bold-WebM.woff");
  font-weight: $tf-bold;
}

@font-face {
  font-family: "Sang Bleu";
  src: url("../fonts/SangBleuOGSans-Bold-WebXL.woff");
  font-weight: $tf-bold;
}

@font-face {
  font-family: "Sang Bleu";
  src: url("../fonts/SangBleuOGSans-Regular-WebXL.woff");
  font-weight: $tf-regular;
}

$tf-font-default: sans-serif;
$tf-font-text: "Suisse Intl";
$tf-font-title: "Sang Bleu";

// Misc
$tf-border-radius: 2rem;
$tf-border-color: $tf-grey-light;
$tf-border-color-hover: $tf-grey-mid;
$tf-border: thin solid $tf-border-color;
$tf-border-gold: medium solid $tf-gold;
$tf-placeholder-color: $tf-grey-mid-light;
$tf-layout-shadow: 3px 4px 3px rgba($tf-black, 0.06);
$golden-ratio: 1.618;
$tf-padding: 1.5rem;

// Navbar
$tf-navbar-height: 3.5rem;

//Switch
$switch-height: 1.8rem;
$switch-margin: 0.2rem;
$switch-transition: 0.4s ease;
$switch-active-color: #43b447;

// Modal
$modal-padding-v: 2rem;
$modal-padding-bottom: 0.5rem;
$modal-padding-mobile-v: 3rem;
$modal-padding-h: 2.5rem;
$modal-padding-mobile-h: 1rem;
$modal-border-radius: 1rem;
$modal-desktop-min-width: 600px;

// Artwork Modal
$artwork-modal-padding-h: $modal-padding-mobile-v;
$artwork-modal-text-padding-h: $modal-padding-h;
$artwork-modal-text-padding-mobile-h: $modal-padding-mobile-h;
$artwork-modal-text-padding-v: $modal-padding-v;

/*
 * Sidebar
 */
// Global
$tf-sidebar-desktop-width: 22rem;
$tf-sidebar-mobile-width: 85vw;
$tf-sidebar-transition-params: 0.6s ease-in-out;
$tf-sidebar-padding: 1.5rem;
$tf-sidebar-padding-v-mobile: 1rem;
$tf-sidebar-padding-h-mobile: 0.7rem;
$tf-sidebar-content-padding: 1.5rem;
$tf-sidebar-header-height: 4rem;
$tf-sidebar-title-font-size: 1.3rem;
$tf-sidebar-title-font-size-mobile: 1.2rem;
$tf-sidebar-close-font-size: 0.9rem;
$tf-sidebar-footer-font-size: 0.8rem;
$tf-sidebar-mobile-close-opacity: 0.2;

//Accordion
$tf-sidebar-accordion-button-height: 3rem;
$tf-sidebar-accordion-button-padding-v: 0.7rem;
$tf-sidebar-accordion-button-padding-h: 1rem;
$tf-sidebar-accordion-button-margin-v: 0.5rem;
$tf-sidebar-accordion-button-name-font-size: 1rem;
$tf-sidebar-accordion-button-name-padding-l: 0.5rem;
$tf-sidebar-accordion-angle-transition-params: 0.2s ease;
$tf-sidebar-accodrion-dropdwon-title-font-size: 0.9rem;
$tf-sidebar-accodrion-dropdwon-font-size: 1rem; // Must be at least 1rem so iOS won't zoom.... https://medium.com/cssjunction/how-to-stop-zoom-in-on-input-focus-on-mobile-devices-835edcaa2ba4
$tf-sidebar-accodrion-dropdwon-group-font-size: 1rem;
$tf-sidebar-festival-switch-explanation-font-size: 0.8rem;

/*
 * Gallery Header
 */
// Texts
$tf-header-h1-size-mobile: 1rem;
$tf-header-h1-size: 1.2rem;
$tf-header-h1-size-wide: 1.5rem;
$tf-header-title-size-mobile: 1.8rem;
$tf-header-title-size: 2.5rem;
$tf-header-title-size-wide: 3.5rem;
$tf-header-subtitle-size-mobile: 1rem;
$tf-header-subtitle-size: 1.1rem;
$tf-header-input-size-mobile: 0.9rem;
$tf-header-input-size: 1.2rem;

// Filters
$tf-header-filters-select-width: 13rem;
$tf-header-filters-select-menu-width: 20rem;

$tf-header-filters-font-size: 1rem;
$tf-header-filters-container-padding: 1rem;
$tf-header-filter-margin: 0.3rem;
$tf-header-filter-margin-wide: 0.5rem;
$tf-header-filter-padding-l: 1.5rem;
$tf-header-filter-padding-r: 1rem;
$tf-header-filter-padding-v: 0.7rem;
$tf-header-filter-icon-padding-l: 0.8rem;
$tf-header-filter-border-radius: 2rem;

$tf-header-filters-font-size-mobile: 0.8rem;
$tf-header-filter-margin-mobile: 0.1rem;
$tf-header-filter-padding-l-mobile: 1rem;
$tf-header-filter-padding-r-mobile: 0.4rem;
$tf-header-filter-padding-v-mobile: 0.2rem;
$tf-header-filter-icon-padding-l-mobile: 0.6rem;

// Buttons
$tf-header-buttons-icon-width: 1.3rem;
$tf-header-buttons-icon-width-mobile: 1rem;
$tf-header-buttons-icon-margin-r: 0.5rem;
$tf-header-buttons-margin-v: 0.25rem;
$tf-header-buttons-margin-mobile-h: 0.25rem;
$tf-header-buttons-margin-h: 0.7rem;

/*
 * End of Gallery Header
 */

/*
 * Card
 */
// Global
$tf-card-width: 28.75rem;
$tf-card-height: calc(#{$tf-card-width} * #{$golden-ratio});
$tf-card-gap: 3rem;
$tf-card-gap-full-width: 1rem;
$tf-card-shadow-transition-params: 0.4s;
$tf-card-border-radius: 0.75rem;
$tf-card-box-shadow: 3px 4px 3px rgba($tf-black, 0.06);
$tf-card-box-shadow-hover: 5px 7px 10px rgba($tf-black, 0.1);
$tf-card-padding-v: 1rem;
$tf-card-padding-h: 3.5rem; // Not on mobile
$tf-card-content-width: calc(#{$tf-card-width} - (2 * (#{$tf-card-padding-h})));

// Determine at which point the card content width take the full width of the screen
$tf-card-not-100vw-breakpoint: $tf-card-width;
@mixin from-card-not-100vw {
  @media screen and (min-width: $tf-card-not-100vw-breakpoint) {
    @content;
  }
}

// Determine at which point the card content width take the full width of the screen (with some padding)
$tf-card-not-100vw-breakpoint-with-padding: calc(#{$tf-card-width} + #{$tf-padding});
@mixin from-card-not-100vw-with-padding {
  @media screen and (min-width: $tf-card-not-100vw-breakpoint-with-padding) {
    @content;
  }
}

// Header
$tf-card-avatar-size: 4.2rem;
$tf-card-header-height: $tf-card-avatar-size;
$tf-card-header-padding: 1rem; // Left and right
$tf-card-header-text-size: 0.75rem;

// Subheader
$tf-card-name-text-size: 1.1rem;
$tf-card-job-text-size: 0.85rem;
$tf-card-subheader-line-height: 1.5rem;
$tf-card-subheader-job-margin-top: -0.6rem;
$tf-card-subheader-padding-t: 0.2rem;
$tf-card-subheader-padding-b: 0rem;
$tf-card-subheader-padding-mobile-h: 1rem; // Left and right

//Bio
$tf-card-bio-text-size: 0.82rem;
$tf-card-bio-number-of-lines: 3;
$tf-card-bio-line-height: 1.2rem; // Hardcode line-height as it is used to calculate bio container height
$tf-card-bio-padding-v: 0rem;
$tf-card-bio-padding-h: 1rem;
$tf-card-bio-height: calc((#{$tf-card-bio-line-height} * #{$tf-card-bio-number-of-lines}) + #{$tf-card-bio-padding-v});
$tf-card-bio-hidden-transition-params: 0.5s ease;
$tf-card-bio-hidden-padding-b: 2rem;
$tf-card-bio-hidden-padding-t: 1rem;

// Next
$tf-card-next-text-size: 0.6rem;
$tf-card-next-padding-t: 0.3rem;

// Buttons
$tf-card-buttons-icon-size: 1rem;
$tf-card-cta-text-size: 0.7rem;
$tf-card-buttons-height: 2.7rem;
$tf-card-buttons-padding-h: 0.5rem;

// Artworks
$tf-card-artwork-large-ratio: 1.339;
$tf-card-artwork-medium-large-ratio: calc(1 / 1.37); // normally 1/1.33
$tf-card-artwork-medium-small-ratio: calc(1 / 2.06); // normally 1/2
$tf-card-artwork-small-ratio: calc(1 / 2.75); // normally 1/2.66
$tf-card-artwork-w: calc(#{$tf-card-width} - (#{$tf-card-padding-h} * 2));
$tf-card-artwork-large-h: calc(#{$tf-card-artwork-w} * #{$tf-card-artwork-large-ratio});
$tf-card-artwork-medium-large-h: calc(#{$tf-card-artwork-w} * #{$tf-card-artwork-medium-large-ratio});
$tf-card-artwork-medium-small-h: calc(#{$tf-card-artwork-w} * #{$tf-card-artwork-medium-small-ratio});
$tf-card-artwork-small-h: calc(#{$tf-card-artwork-w} * #{$tf-card-artwork-small-ratio});

$tf-card-artwork-type-text-size: 0.8rem;
$tf-card-artwork-title-text-size: 0.8rem;
$tf-card-artwork-info-line-height: 1rem;
$tf-card-artwork-info-pagging-t: 0.2rem;
$tf-card-artwork-info-pagging-b: 0.4rem;

$tf-card-artwork-desc-padding-v: 0.5rem;
$tf-card-artwork-desc-padding-h: 1rem;
$tf-card-artwork-desc-background: rgba($tf-grey-dark, 0.7);
$tf-card-artwork-desc-transition-params: 1s ease;
$tf-card-artwork-desc-text-size: 0.875rem;

$tf-card-artwork-icon-container-size: 1.3rem;
$tf-card-artwork-icon-container-offset: 0.3rem;
$tf-card-artwork-icon-size: 0.8rem;
/*
 * End of Card
 */

$tf-description-font-size: 0.8rem;
$tf-description-default-color: $tf-grey-mid;

.svgIcon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin owardInput {
  width: 100%;
  background: transparent;
  &::placeholder {
    color: $tf-grey-mid-light;
  }
  color: $tf-grey-darker;
  border: 1px solid;
  border-color: $tf-grey-light;
  border-radius: $tf-border-radius;
  outline: none;
  padding: 0 1rem;

  &:hover {
    border-color: $tf-grey-mid;
  }
}

@mixin owardLink {
  color: $tf-blue;
  cursor: pointer;

  &:hover {
    color: $tf-blue-logo;
  }
}

@mixin owardDescription {
  font-size: $tf-description-font-size;
  color: $tf-description-default-color;
}

@mixin owardThinBorder {
  border: thin solid $tf-grey-light;
}

@mixin unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@mixin toasterLink {
  font-weight: $tf-semibold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@mixin artworkGrid {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 100vw);
  gap: 1rem;
  grid-gap: 1rem;

  @include from-card-not-100vw {
    grid-template-columns: repeat(auto-fit, calc(#{$tf-card-width} - 2 * #{$tf-card-padding-h}));
  }

  .artworkContainer {
    width: 100%;
    @include from-card-not-100vw {
      width: calc(#{$tf-card-width} - 2 * #{$tf-card-padding-h});
    }
  }
}
