@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

@include mobile {

  .label {
    display: none;
  }
}

html {
  .icon {
    width: 3rem;
  }
}
