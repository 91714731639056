@import '../../../assets/sass/bulma-customization.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';


.background {
  background: linear-gradient(to right, $tf-pink, $tf-blue);
}

.heroHead {
  padding: 2%;
}

.heroFoot {
  line-height: 3rem;
  padding: 2%;
}

.text {
  font-size: 2rem;

  @include desktop {
    font-size: 8rem;
  }

  @include widescreen {
    font-size: 10rem;
  }
}
