@import "../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/generic.sass";
@import "~bulma/sass/utilities/initial-variables.sass";

:export {
  bodySize: $body-size;
  navbarHeight: $navbar-height;
  tabletSize: $tablet;
  desktopSize: $desktop;
  cardFullWidthBreakpoint: $tf-card-not-100vw-breakpoint;
  sideBarDesktopWidth: $tf-sidebar-desktop-width;
  sideBarMobileWidth: $tf-sidebar-mobile-width;
  sidebarTransitionParams: $tf-sidebar-transition-params;
  sidebarCloseMobileOpacity: $tf-sidebar-mobile-close-opacity;
  cardWidth: $tf-card-width;
  cardHeight: $tf-card-height;
  cardGap: $tf-card-gap;
  cardGapFullWidth: $tf-card-gap-full-width;
}

@keyframes flashAnimationKeyframes {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  60% {
    opacity: 0.6;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.flashAnimation {
  $flash-animation: flashAnimationKeyframes 0.4s ease 0s;

  -webkit-animation: $flash-animation;
  -moz-animation: $flash-animation;
  -o-animation: $flash-animation;
  animation: $flash-animation;
}
