@import '@assets/sass/bulma-customization.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

.goToGalleryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .h1 {
    color: $tf-grey-mid-dark;
    padding-bottom: 0.3rem;
    font-size: $tf-header-h1-size-mobile;

    @include desktop {
      font-size: $tf-header-h1-size;
    }

    @include widescreen {
      font-size: $tf-header-h1-size-wide;
    }
  }

  .title {
    padding-bottom: 0.8rem;
    font-size: $tf-header-title-size-mobile;

    @include desktop {
      font-size: $tf-header-title-size;
    }

    @include widescreen {
      font-size: $tf-header-title-size-wide;
    }
  }

  .shortlistDescription {
    white-space: pre-wrap;
    padding-bottom: 0.5rem;
  }

  .shortlistPrivate {
    padding: 1rem 0;
    font-weight: $tf-semibold;
  }

  .buttonsContainer {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; // multiple lines, for example on small mobiles

    @include from-card-not-100vw {
      justify-content: flex-start;
    }
  }

}
