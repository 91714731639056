@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.messagesDiscussionContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10; // Stays on top of sidebar
  left: 100vw; // Controled by Javascript (in layout-store)
  width: 100vw; // Controled by Javascript (in layout-store)

  @include desktop {
    left: 0;
    margin-left: $tf-sidebar-desktop-width;
    width: calc(100% - #{$tf-sidebar-desktop-width});
  }

  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    /* Fallback for browsers that do not support Custom Properties ; see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
    height: calc(100vh - #{$navbar-height});
    height: calc(var(--vh, 1vh) * 100 - #{$navbar-height});
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */

    height: calc(100vh - #{$navbar-height});
    height: calc(var(--vhSafari, 1vh) * 100 - #{$navbar-height});
  }

  background-color: $tf-white;
  @include owardThinBorder();
  transition: left $tf-sidebar-transition-params;

  .discussionHeaderContainer {
    border-bottom: thin solid $tf-grey-light;
    display: flex;
    justify-content: center;
    align-items: center;

    .closeDiscussionContainer {
      z-index: 2000; // above Oward Loader
      flex: 1;
      display: block;

      @include desktop {
        display: none;
      }

      padding: 0.5rem;

      .closeDiscussion {
        cursor: pointer;
        padding: 0.3rem;
        border-radius: 10rem;

        &:hover {
          background-color: $tf-grey-lighter;
        }
      }
    }

    .parameterContainer {
      flex: 1;
      padding: 0.5rem;

      .parameterButton {
        cursor: pointer;
        padding: 0.3rem;
        border-radius: 10rem;

        &:hover {
          background-color: $tf-grey-lighter;
        }
        &:active {
          background-color: $tf-grey-light;
        }
      }
    }

    .profileContentContainer {
      flex: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      padding: 0.5rem;

      @include desktop {
        padding: 0.5rem 1rem;
      }

      border-radius: $tf-border-radius;

      cursor: pointer;

      &:hover {
        background-color: $tf-grey-lighter;
      }

      &:active {
        background-color: $tf-grey-light;
      }

      $avatar-size: 3rem;

      :export {
        avatarSize: $avatar-size;
      }

      .avatarContainer {
        width: $avatar-size;
        height: $avatar-size;

        .avatar {
          width: $avatar-size;
          height: $avatar-size;
          object-fit: cover;
          border-radius: calc(#{$avatar-size} / 2);
        }

        .goldenBorder {
          border: $tf-border-gold;
        }
      }

      .nameJobContainer {
        position: relative;
        padding-left: 1rem;
        margin-right: 0.5rem;

        .name {
          position: relative;
          font-family: $tf-font-title;
          font-weight: $tf-bold;
          font-size: 1rem;
          max-width: calc(100vw - 9rem);

          @include tablet() {
            font-size: 1.2rem;
          }

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .job {
          font-size: 0.8rem;

          @include tablet() {
            font-size: 1rem;
          }

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .groupDiscussionContainer {
        position: relative;
        padding-left: 1rem;
        margin-right: 0.5rem;

        .name {
          position: relative;
          font-family: $tf-font-title;
          font-weight: $tf-bold;
          font-size: 1rem;
          max-width: calc(100vw - 9rem);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          @include tablet() {
            font-size: 1.2rem;
          }

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .type {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 0.8rem;

          @include tablet() {
            font-size: 1rem;
          }

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .sendMessageContainer {
    width: 100%;
    padding: 0 0.5rem 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .spectatorCantSend {
      text-align: center;
      color: $tf-grey-mid;
      font-style: italic;
      font-size: 0.8rem;
    }
  }

  .sendMessageContainerPicture {
    width: 100%;
    padding: 0 0.5rem 0.5rem 0;
    display: flex;
    align-items: center;
    border-top: 0.1rem solid lightgrey;
  }

  .noDiscussionContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    white-space: pre-wrap;
    text-align: center;
  }

  .uploadButton {
    @include desktop {
      width: 3.5rem;

      .uploadButton > i {
        text-align: left;
      }

      text-align: center;
    }
  }
}

.pictureMainContainer {
  margin: 0.5rem auto;
  position: relative;
  vertical-align: middle;
  padding: 1rem 1rem 0 1rem;
  height: 10rem;

  @include desktop() {
    height: 15rem;
  }

  .pictureContainer {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;

    .picture {
      max-height: 100%;
      max-width: 100%;
      border-radius: calc(#{$tf-border-radius} / 2);
    }
  }

  .deletePictureContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      font-size: $tf-sidebar-close-font-size;
      color: $tf-grey-dark;

      &:hover {
        cursor: pointer;
        color: $tf-grey-darker;
      }
    }
  }
}
