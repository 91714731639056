@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 3rem;

:export {
  avatarSize: $avatar-size;
}

.title {
  @include desktop {
    font-size: 1.5rem;
  }
  font-weight: $tf-semibold;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.mainContainer {
  min-width: 80vw;
  @include tablet {
    min-width: $modal-desktop-min-width;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $tf-grey-darker;
  position: relative;

  .hr {
    margin: 0 0;
  }

  .desc {
    text-align: center;
    padding-bottom: 1rem;
  }

  .contentContainer {
    padding: $modal-padding-v 0;

    .error {
      text-align: center;
      color: $tf-red;
    }

    .listContainer {
      position: relative;
      padding: 0.5rem 1rem;
      background-color: $tf-grey-lighter;
      border-radius: 0.5rem;
      border: thin solid $tf-grey-light;
    }

    .profileContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $tf-white;
      border: thin solid $tf-grey-light;
      border-radius: 0.5rem;
      margin: 0.3rem 0;
      padding: 0.5rem;
      position: relative;

      &:hover {
        border: thin solid $tf-grey-mid;
        cursor: grab;
      }

      .descContainer {
        padding-bottom: 1.5rem;

        .desc {
          text-align: center;
        }
      }

      .avatarContainer {
        //flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: $avatar-size;
          height: $avatar-size;
          object-fit: cover;
          border-radius: calc(#{$avatar-size} / 2);
        }
      }

      .initialsContainer {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: calc(#{$avatar-size} / 2);
        border: thin solid $tf-grey-light;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $tf-grey-mid;

        .initials {
          overflow: hidden;
          font-family: $tf-font-title;
          font-weight: $tf-bold;
          font-size: 1.2rem;
          text-overflow: ellipsis;
          color: $tf-white;
        }
      }

      .name {
        padding-left: 0.5rem;
      }

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    .draggingProfileContainer {
      border: thin solid $tf-blue;
    }
  }
}
