@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  width: 100%;
  height: 100%;
  // The bottom padding is included in the footer container
  padding: $tf-sidebar-padding-v-mobile $tf-sidebar-padding-h-mobile 0 $tf-sidebar-padding-h-mobile;
  @include desktop {
    padding: $tf-sidebar-padding $tf-sidebar-padding 0 $tf-sidebar-padding;
  }
  display: flex;
  flex-direction: column;

  .headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: calc(#{$tf-sidebar-padding-v-mobile} - #{$tf-sidebar-title-font-size-mobile}/ 2);
    @include desktop {
      padding-bottom: calc(#{$tf-sidebar-padding} - #{$tf-sidebar-title-font-size}/ 2);
    }

    .titleContainer {
      flex: 20;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: $tf-border-radius;

      .title {
        font-weight: $tf-bold;
        font-family: $tf-font-title;
        font-size: $tf-sidebar-title-font-size-mobile;

        @include desktop {
          font-size: $tf-sidebar-title-font-size;
        }

        .icon {
          margin-right: 0.5rem;
        }
      }

      .svgIconContainer {
        position: relative;
        margin-right: 0.5rem;
        height: $tf-sidebar-title-font-size-mobile;
        width: $tf-sidebar-title-font-size-mobile;
        @include desktop {
          height: $tf-sidebar-title-font-size;
          width: $tf-sidebar-title-font-size;
        }

        .svgIcon {
          position: absolute;
        }
      }
    }

    .closeContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        font-size: $tf-sidebar-close-font-size;
        color: $tf-grey-dark;

        &:hover {
          cursor: pointer;
          color: $tf-grey-darker;
        }
      }
    }
  }

  .contentContainer {
    flex: 10;
    padding-bottom: $tf-sidebar-content-padding;
  }

  .searchContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .flashContainer {
    $flash-margin: 1rem;
    position: absolute;
    background-color: $tf-gold;
    border-radius: $tf-border-radius;
    top: -$flash-margin;
    left: -$flash-margin;
    width: calc(100% + 2 * #{$flash-margin});
    height: calc(100% + 2 * #{$flash-margin});
    z-index: 1;
    opacity: 0;
    visibility: hidden;
  }

  .footerContainer {
    padding-bottom: $tf-sidebar-padding;
    p {
      font-size: $tf-sidebar-footer-font-size;
      color: $tf-grey-mid;
    }

    .link {
      color: $tf-grey-mid;
      cursor: pointer;

      &:hover {
        color: $tf-grey-dark;
      }
    }
  }
}
