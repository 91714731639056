@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.stripeContainer {
  margin: 1.5rem 0 6rem 0;
  padding: 1rem 0.5rem;
  border-radius: $tf-border-radius;
  border: thin solid $tf-grey-light;
}

.defaultOrNewCardContainer {
  margin: 1.5rem 0 6rem 0;
  .defaultContainer {
    margin: 1rem;
    padding: 1rem;
    border-radius: $tf-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: thin solid $tf-grey-light;

    .title {
      font-weight: $tf-book;
    }

    .cardInfoContainer {
      padding: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: thin solid $tf-grey-light;
      background-color: $tf-grey-lighter;
      border-radius: $tf-card-border-radius;

      .brandIcon {
        margin-right: 0.5rem;
      }

      .number {
        font-weight: $tf-semibold;
      }

      .expiration {
        font-size: 0.9rem;
        color: $tf-grey-mid;
      }
    }
  }

  .newCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
      @include owardLink();
    }
  }
}

.noPaymentNeededContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .noPaymentNeeded {
    font-weight: $tf-semibold;
    margin-bottom: $tf-padding;
  }
}

.paymentInfosContainer {
  text-align: center;
  margin-top: 1rem;

  .firstPayment {
    color: $tf-grey-mid;
    font-style: italic;
  }

  .latePayment {
    color: $tf-red;
    font-weight: $tf-semibold;
  }
}
