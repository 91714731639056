@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.sidebarContainer {
  position: fixed;
  background-color: $tf-white;
  border: thin solid $tf-grey-light;
  top: $navbar-height; // Stay at the top (just under the navbar)
  /* Fallback for browsers that do not support Custom Properties ; see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  height: calc(100vh - #{$navbar-height});
  height: calc(var(--vh, 1vh) * 100 - #{$navbar-height});
  overflow-x: hidden;
  padding: 0 0.5rem;
  left: 0; // Controled by Javascript (in layout-store)
  width: 100vw; // Controled by Javascript (in layout-store)
  @include desktop {
    left: 0;
    width: $tf-sidebar-desktop-width;
  }

  .currentProfileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;

    border-radius: $tf-border-radius;

    $avatar-size: 3rem;
    :export {
      avatarSize: $avatar-size;
    }

    .avatarContainer {
      min-width: $avatar-size;
      height: $avatar-size;

      .avatar {
        width: $avatar-size;
        height: $avatar-size;
        object-fit: cover;
        border-radius: calc(#{$avatar-size} / 2);
      }

      .goldenBorder {
        border: $tf-border-gold;
      }
    }

    .text {
      padding-left: 0.5rem;
      font-weight: $tf-semibold;
      font-family: $tf-font-title;
      font-size: 1rem;
      text-align: center;
    }
  }

  .currentProfileContainerClickable {
    cursor: pointer;
    &:hover {
      background-color: $tf-grey-lighter;
    }
  }

  .newGroupDiscussionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .discussionLinesContainer {
    padding-top: 1rem;
    border-top: thin solid $tf-grey-light;

    .noDiscussionContainer {
      color: $tf-grey-mid-dark;
      text-align: center;
      white-space: pre-wrap;
      font-size: 0.9rem;
      padding: $tf-padding;
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttonContainer {
        padding-top: 1rem;
      }
    }

    .discussionLineContainer {
      padding: 0.75rem 1rem;
      border-radius: $tf-border-radius;
      cursor: pointer;
      color: $tf-grey-mid;
      font-weight: $tf-light;

      &:hover {
        background-color: $tf-grey-lighter;
      }

      &:active {
        background-color: $tf-grey-light;
      }

      .discussionLineContentContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        $avatar-size: 3rem;
        :export {
          avatarSize: $avatar-size;
        }

        .avatarContainer {
          width: $avatar-size;
          height: $avatar-size;

          .avatar {
            width: $avatar-size;
            height: $avatar-size;
            object-fit: cover;
            border-radius: calc(#{$avatar-size} / 2);
          }

          .goldenBorder {
            border: $tf-border-gold;
          }
        }

        .groupIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $tf-grey-darker;
        }

        .rightContainer {
          flex: 100;
          position: relative;
          padding-left: 1rem;
          margin-right: 0.5rem;
          display: table;
          table-layout: fixed;
          width: 100%;

          .name {
            position: relative;
            font-family: $tf-font-title;
            font-weight: $tf-semibold;
            font-size: 1rem;
            color: $tf-grey-dark;

            @include tablet() {
              font-size: 1rem;
            }

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .messageAndTime {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .message {
              flex: 100;
              font-size: 0.8rem;

              @include tablet() {
                font-size: 0.9rem;
              }

              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .time {
              flex: 1;
              padding-left: 0.3rem;
              font-size: 0.7rem;

              @include tablet() {
                font-size: 0.8rem;
              }
              color: $tf-grey-mid;
            }
          }
        }
      }
    }

    .discussionLineActive {
      @include desktop() {
        background-color: $tf-grey-lighter;
      }
    }

    .discussionLineUnread {
      font-weight: $tf-semibold;
      color: $tf-grey-darker !important;
    }
  }
}
