@import "@assets/sass/bulma-customization.scss";

:export {
  greyDark: $tf-grey-dark;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.4;

  .absolutePositionedLoader {
    position: absolute;
    top: 1rem;
  }

  .messageContainer {
    padding-top: 1rem;
    color: $tf-black;

    @include unselectable();
  }
}

.loaderContainerStatic {
  position: static;
  background-color: transparent;
}
