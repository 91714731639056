@import "../../../../../assets/sass/bulma-customization.scss";

.container {
  //max-width: 990px;
  padding: 0 20px;
  margin: auto;
}

.header {
  padding-top: 20px;
}

.footer {
  padding-bottom: 20px;
}

.divider {
  margin: 1rem 0;
  border-top: thin solid $tf-grey-light;
}

.divider.bigMargin {
  margin: 3rem 0;
}
