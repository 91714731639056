@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    /* Fallback for browsers that do not support Custom Properties ; see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
    height: calc(100vh - #{$navbar-height});
    height: calc(var(--vh, 1vh) * 100 - #{$navbar-height});
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    height: calc(100vh - #{$navbar-height});
    height: calc(var(--vhSafari, 1vh) * 100 - #{$navbar-height});
  }

  width: 100vw;
  left: 0;
}
