@import "../../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

:export {
  greyDark: $tf-grey-dark;
}

@mixin gridPadding {
  /* Padding helper : top | right | bottom | left */
  padding: 0 0 6rem 0;
  @include from-card-not-100vw {
    padding: 0.5rem 1rem 6rem 1rem;
  }

  @include desktop {
    padding: 1rem 1.5rem 6rem 1.5rem;
  }
  @include widescreen {
    padding: 1rem 1.5rem 6rem 1.5rem;
  }
  @include fullhd {
    padding: 1rem 2rem 6rem 2rem;
  }
}

.mainContainer {
  width: 100%;
  position: relative;
  opacity: 1; // Modified when loading
}

.gridCardContainer {
  display: grid;
  gap: $tf-card-gap-full-width;
  grid-gap: $tf-card-gap-full-width;

  @include from-card-not-100vw {
    display: grid;
    grid-template-columns: repeat(auto-fit, #{$tf-card-width});
    justify-content: center;
    gap: $tf-card-gap;
    grid-gap: $tf-card-gap;
  }

  @include gridPadding();
}

.gridArtworkContainer {
  @include artworkGrid();
  @include gridPadding();
}

.loaderContainer {
  position: absolute;
  height: $tf-card-height;
  width: 100%;
  z-index: 6;
  display: flex;
  padding-top: 6rem;
  justify-content: center;
}

.loaderMoreContainer {
  height: 8rem;
  @include from-card-not-100vw {
    height: $tf-card-height;
  }
  width: 100vw;
  @include from-card-not-100vw {
    width: 100%;
  }
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderMoreContainerArtwork {
  height: 8rem;
  @include from-card-not-100vw {
    height: $tf-card-artwork-medium-small-h;
  }
}

.emptyContainer {
  height: $tf-card-height;
  width: 100vw;
  @include from-card-not-100vw {
    width: auto;
  }
  padding-top: 6rem;
  justify-content: center;
  text-align: center;
}

.displayBottomBarEmptyDiv {
  position: absolute;
  top: 75vh;
  height: 100%;
}
