@import "../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.buttonsContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $tf-white;
  padding: $tf-card-buttons-padding-h 0 $tf-card-buttons-padding-h 0;
  max-height: $tf-card-buttons-height;
}

.buttonsIconContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .buttonsIconCircleContainer {
    width: 60%;
    padding-bottom: 60%;
    position: relative;
    cursor: pointer;

    border-radius: 10rem;
    &:hover {
      background-color: $tf-grey-lighter;
    }

    .buttonsIcon {
      position: absolute;
      width: 50%;
      height: 50%;
      left: 25%;
      top: 25%;
    }
  }

  .buttonsIconCircleContainer.preview {
    background-color: transparent;
    cursor: default;
  }

  .buttonsIconCircleContainer.deactivated {
    background-color: transparent;
    cursor: default;

    .buttonsIcon.deactivated {
      fill: $tf-grey-light;
    }
  }
}

.ctaContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  border: thin solid $tf-blue;
  border-radius: $tf-border-radius;
  cursor: pointer;

  &:hover {
    border: thin solid $tf-blue-logo;
  }

  .cta {
    color: $tf-blue;
    font-size: $tf-card-cta-text-size;
    font-weight: $tf-semibold;
  }
}

.ctaContainer.notActive {
  cursor: default;

  border: thin solid $tf-grey-mid-light;

  .cta {
    color: $tf-grey-mid-light;
  }
}

.ctaContainer.preview {
  cursor: default;
}

.ctaContainer.preview.active {
  cursor: default;
  background-color: $tf-blue;
  &:hover {
    background-color: $tf-blue;
  }
}

.toasterLink {
  @include toasterLink();
}
