@charset "utf-8";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import "~react-lazy-load-image-component/src/effects/blur.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~react-image-crop/lib/ReactCrop.scss";
@import "~react-dates/lib/css/_datepicker.css";

@import "./bulma-customization.scss";
@import "~bulma/bulma";
@import "~@creativebulma/bulma-tooltip";

// Block gallery scroll when modal is opened
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

// react-toastify customization
.Toastify__toast {
  border-radius: $tf-card-border-radius !important;
  font-weight: $tf-book !important;
  cursor: default !important;
  padding-left: 1.5rem !important;
}

.Toastify__toast--default {
  background: $tf-white !important;
  color: $tf-grey-darker !important;
}
.Toastify__toast--dark {
  background: $tf-grey-dark !important;
  color: $tf-white !important;
}

.Toastify__toast--error {
  background: $tf-red-dark !important;
}

// react-dates customisation
.SingleDatePickerInput {
  border: 0 solid $tf-border-color !important;
  border-radius: $tf-border-radius !important;
  overflow: hidden !important;
}

.SingleDatePicker_picker {
  z-index: 5 !important;
}

.DateInput_input {
  font-size: 1rem !important;
  line-height: 1rem !important;
  font-weight: $tf-regular !important;
  color: $tf-grey-darker !important;
  border: 1px solid $tf-border-color !important;
  border-radius: $tf-border-radius !important;

  &:hover {
    border: 1px solid $tf-border-color-hover !important;
  }
}

.DateInput_input__focused {
  border: 1px solid $tf-blue !important;
  border-radius: $tf-border-radius !important;
}

.CalendarDay__selected {
  background: $tf-blue !important;
  color: white !important;
  border: 0 !important;
}
.CalendarDay__selected:hover {
  background: $tf-blue-logo !important;
  color: white !important;
}
