@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.seeOrContactProfileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .name {
    margin-top: 0.5rem;
    font-family: $tf-font-title;
    font-weight: $tf-semibold;
    font-size: 1.4rem;
    @include desktop() {
      font-size: 1.5rem;
    }
  }

  .locationContainer {
    margin-top: -0.5rem;
    .location {
      color: $tf-grey-mid;
    }
  }

  .job {
    margin-top: 0.5rem;
    font-weight: $tf-semibold;
    font-size: 1.1rem;
  }

  .seePortfolioContainer {
    margin: 1.5rem 0;

    .notPublished {
      color: $tf-grey-mid-dark;
      font-style: italic;
      font-weight: $tf-semibold;
    }
  }

  .buttonsContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $tf-white;
    padding: $tf-card-buttons-padding-h 0 $tf-card-buttons-padding-h 0;
    max-height: $tf-card-buttons-height;
  }
}
