@import "@assets/sass/bulma-customization.scss";

:export {
  greyDark: $tf-grey-dark;
}

$hashtag-default-border-color: $tf-grey-light;
$hashtag-active-background-color: $tf-blue;

.mainContainer {
  width: 100%;

  .searchContainer {
    display: flex;

    .reloadContainer {
      display: flex;
      height: $tf-sidebar-accordion-button-height;
      width: $tf-sidebar-accordion-button-height;
      border-radius: $tf-border-radius;
      border: 1px solid;
      border-color: $tf-grey-light;
      padding: $tf-sidebar-accordion-button-padding-v $tf-sidebar-accordion-button-padding-h;
      margin-left: $tf-sidebar-accordion-button-margin-v;

      &:hover {
        cursor: pointer;
        border-color: $tf-blue;
      }
    }
  }

  .hashtagContainer {
    text-align: center;
    position: relative;

    .msg {
      padding-top: 0.5rem;
      color: $tf-grey-mid;
    }

    .gridContainer {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-evenly;

      .hashtagContainer {
        border: solid 1px;
        border-radius: $tf-border-radius;
        border-color: $hashtag-default-border-color;
        margin: 0.2rem;
        padding: 0.3rem 0.6rem;
        color: $tf-grey-dark;

        &:hover {
          cursor: pointer;
          border-color: $tf-blue;
        }
      }

      .active {
        background-color: $hashtag-active-background-color;
        border-color: $hashtag-active-background-color;
        color: white;

        &:hover {
          background-color: $tf-blue-logo;
          border-color: $tf-blue-logo;
        }
      }
    }
  }
}
