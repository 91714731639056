@import '@assets/sass/bulma-customization.scss';

$track-color: $tf-grey-dark;
$thumb-color: $tf-grey-dark;

$thumb-radius: 15px;
$thumb-height: 15px;
$thumb-width: 15px;

$track-width: 100%;
$track-height: 2px;

$track-radius: 10px;
$contrast: 5%;

@mixin track {
  width: $track-width;
  height: $track-height;
  cursor: pointer;
  animate: 0.2s;

  &:hover {
    background: $tf-grey-darker;
  }
}

@mixin thumb {
  border: none;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-radius;
  background: $thumb-color;
  cursor: pointer;

  &:hover {
    background: $tf-grey-darker;
  }
}

.slider {
  background: transparent;
  -webkit-appearance: none;
  margin: calc(#{$thumb-height} / 2) 0;
  width: $track-width;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include track();
    background: $track-color;
    border-radius: $track-radius;
    border: none;
  }

  &::-webkit-slider-thumb {
    @include thumb();
    -webkit-appearance: none;
    margin-top: calc((#{$track-height} / 2) - (#{$thumb-height} / 2));
  }

  &:focus::-webkit-slider-runnable-track {
    background: lighten($track-color, $contrast);
  }

  &::-moz-range-track {
    @include track();
    background: $track-color;
    border-radius: $track-radius;
    border: none;
  }
  &::-moz-range-thumb {
    @include thumb();
  }

  &::-ms-track {
    @include track();
    background: transparent;
    border-color: transparent;
    border-width: $thumb-width 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: darken($track-color, $contrast);
    border: none;
    border-radius: calc(#{$track-radius} * 2);
  }
  &::-ms-fill-upper {
    background: $track-color;
    border: none;
    border-radius: calc(#{$track-radius} * 2);
  }
  &::-ms-thumb {
    @include thumb();
  }
  &:focus::-ms-fill-lower {
    background: $track-color;
  }
  &:focus::-ms-fill-upper {
    background: lighten($track-color, $contrast);
  }
}
