@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 2rem;

:export {
  avatarSize: $avatar-size;
}

.membersRoleMainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .membersContainer {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
  }
}
