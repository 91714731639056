@import '@assets/sass/bulma-customization.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

.title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: $tf-semibold;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.contentContainer {
  height: 100%;
  min-width: 80vw;
  @include tablet {
    min-width: $modal-desktop-min-width;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $modal-padding-v 0;
  color: $tf-grey-darker;

  .button {
    outline: none;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: $tf-blue;
    border-radius: $tf-border-radius;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: $tf-blue-logo;
    }

    text-align: center;
    color: white;
    font-weight: $tf-semibold;
  }
}

.error {
  color: $tf-red;
}
