@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  text-align: center;
  position: relative;

  .title {
    font-size: 1.3rem;
    @include tablet {
      font-size: 2rem;
    }
    font-family: $tf-font-title;
    font-weight: $tf-semibold;
    padding-bottom: $tf-padding;
  }

  .subtitle {
    font-weight: $tf-semibold;
    padding-bottom: $tf-padding;
  }

  .description {
    padding-bottom: $tf-padding;
  }
}
