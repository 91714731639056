@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 2rem;
$avatar-size-big: 4rem;

:export {
  avatarSize: $avatar-size;
  avatarSizeBig: $avatar-size-big;
}

.searchedProfileListContainer {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .noProfileText {
    color: $tf-grey-mid;
  }
}

.newMembersMainContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: $tf-border;

  .title {
    font-weight: $tf-semibold;
  }

  .newMembersContainer {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;

    .newMemberContainer {
      margin: 0.25rem;
      padding: 0.25rem;
      @include desktop() {
        padding: 0.5rem;
      }
      border: $tf-border;
      border-radius: $tf-border-radius;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .avatarContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: $avatar-size;
          height: $avatar-size;
          object-fit: cover;
          border-radius: calc(#{$avatar-size} / 2);
        }
      }

      .nameContainer {
        margin-left: 0.5rem;
      }

      .memberIconContainer {
        padding-left: 0.25rem;
        .memberIcon {
          color: $tf-grey-mid-dark;
          cursor: pointer;

          &:hover {
            color: $tf-grey-dark;
          }
        }
      }
    }
  }
}

.profileRectangleContainer {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;
  border: thin solid $tf-grey-light;
  border-radius: $tf-card-border-radius;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    border: thin solid $tf-grey-mid;
  }

  .profileContentContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-right: 1rem;

    .avatarContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      .avatar {
        width: $avatar-size-big;
        height: $avatar-size-big;
        object-fit: cover;
        border-radius: calc(#{$avatar-size-big} / 2);
      }
    }

    .nameJobContainer {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .name {
        font-family: $tf-font-title;
        font-weight: $tf-bold;
        font-size: 1.3rem;

        @include tablet() {
          font-size: 1.5rem;
        }
      }

      .job {
        font-size: 1rem;

        @include tablet() {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.memberCountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .memberCount {
    font-weight: $tf-book;
  }
}

.explanationContainer {
  margin: 1rem 0;

  .knowMore {
    @include owardLink();
    text-align: center;
  }
  color: $tf-grey-darker;

  .explanationName {
    font-weight: $tf-semibold;
  }

  .creatorAsAdmin {
    margin-top: 0.5rem;
    text-align: center;
    color: $tf-grey-mid;
  }
}

.leaveDiscussionContainer {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  text-align: center;

  .leaveDiscussion {
    color: $tf-grey-mid-dark;
    cursor: pointer;

    &:hover {
      color: $tf-grey-darker;
    }
  }
}

.memberContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: $tf-border;
  padding: 0.5rem 0;
  @include desktop() {
    flex-direction: row;
  }

  .avatarAndNameContainer {
    flex: 4;
    display: flex;
    align-items: center;
    flex-direction: row;

    .avatarContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .avatar {
        width: $avatar-size;
        height: $avatar-size;
        object-fit: cover;
        border-radius: calc(#{$avatar-size} / 2);

        transition: border-radius $tf-card-artwork-desc-transition-params !important;
        &:hover {
          border-radius: 0.5rem;
        }
      }
    }

    .name {
      font-weight: $tf-semibold;
      margin-left: 0.5rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .youAdminContainer {
    flex: 6;
    display: flex;
    justify-content: flex-end;

    .role {
      font-style: italic;
    }
  }

  .roleContainer {
    flex: 2;
    display: flex;
    justify-content: flex-end;

    .role {
      font-style: italic;
    }
  }

  .switchContainer {
    flex: 2;
    display: flex;
    justify-content: center;
  }

  .memberIconContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 0.25rem;

    .memberIcon {
      color: $tf-grey-mid-dark;
      cursor: pointer;

      &:hover {
        color: $tf-grey-dark;
      }
    }

    .memberIcon.mobile {
      @include desktop() {
        display: none;
      }
    }

    .memberIcon.desktop {
      @include touch() {
        display: none;
      }
    }
  }
}
