@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 5rem;

:export {
  avatarSize: $avatar-size;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  @include desktop() {
    flex-direction: row;
  }

  margin-bottom: 1rem;
  border: thin solid $tf-grey-light;
  border-radius: $tf-card-border-radius;

  .profileLinkContainer {
    flex: 8;
    display: flex;
    flex-direction: column;
    @include desktop() {
      flex-direction: row;
    }
    padding: 1rem;
    cursor: pointer;
    border-radius: $tf-card-border-radius;
    &:hover {
      background-color: $tf-grey-lighter;
    }

    .profileLeftContainer {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      @include desktop() {
        justify-content: flex-start;
      }
      padding-right: 1rem;

      .avatarContainer {
        //flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: $avatar-size;
          height: $avatar-size;
          object-fit: cover;
          border-radius: calc(#{$avatar-size} / 2);
        }

        .goldenBorder {
          border: $tf-border-gold;
        }
      }

      .nameJobContainer {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;

        .name {
          font-family: $tf-font-title;
          font-weight: $tf-bold;
          font-size: 1.2rem;

          @include tablet() {
            font-size: 1.4rem;
          }

          @include widescreen() {
            font-size: 1.6rem;
          }
        }

        .job {
          font-size: 1rem;

          @include tablet() {
            font-size: 1.2rem;
          }

          @include widescreen() {
            font-size: 1.4rem;
          }
        }
      }
    }

    .profileCenterContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: 1rem;
      padding-right: 0;
      @include desktop() {
        padding-top: 0;
        padding-right: 1rem;
      }
    }
  }

  .profileLinkContainer.deactivated {
    cursor: default;
    &:hover {
      background-color: $tf-white;
    }
  }

  .profileRightContainer {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $tf-card-border-radius;

    .buttonsContainer {
      width: 85%;
      @include tablet() {
        width: 50%;
      }
      @include desktop() {
        width: 100%;
      }
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: $tf-padding;
    }
  }
}

.hr {
  margin: 1rem 0rem;
  height: 1px;
  background-color: $tf-grey-mid-light;
  border: none;
}

.userContainer {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  .userNameAndDate {
    flex: 1;
    padding: 0 1rem 1rem 0;

    text-align: center;
    @include desktop {
      text-align: start;
    }

    .userName {
      font-size: 1.2rem;
      font-weight: $tf-semibold;
    }
  }

  .profilesContainer {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .noProfile {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
  }
}

.profileSelection {
  position: relative;
  padding-bottom: $tf-padding;
}

.noProfileContainer {
  width: 100%;
  flex-direction: column;
  padding: $tf-padding;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;

  .buttonContainer {
    padding-top: $tf-padding;
  }
}

.subtitleContainer {
  padding-bottom: $tf-padding;
  white-space: pre-wrap;
  text-align: center;

  .subtitle {
    font-weight: $tf-light;
    font-size: 1rem;
    @include tablet() {
      font-size: 1.2rem;
    }
  }
}
