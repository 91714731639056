@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

:export {
  greyDarker: $tf-grey-darker;
  placeholderColor: $tf-placeholder-color;
  red: $tf-red;
  regular: $tf-regular;
}

.formContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  width: 100%;
  text-align: center;

  .blockContainer {
    width: 100%;

    $cardInputPadding: 0.5rem;

    .cardElementContainer {
      padding: $cardInputPadding;

      .cardElement {
        @include owardInput();
        padding: 0.7rem;
        cursor: text;
      }
    }

    .makeDefaultContainer {
      margin-top: $cardInputPadding;
      padding: 0 $cardInputPadding;
    }

    .title {
      padding-bottom: 1rem;
      font-family: $tf-font-title;
      font-weight: $tf-semibold;
      font-size: 1.2rem;
    }

    .secured {
      font-size: 0.9rem;
      color: $tf-grey-dark;
      margin-top: -1rem;
      margin-bottom: 1rem;

      .icon {
        font-size: 1.2rem;
        cursor: pointer;
        color: $tf-grey-dark;

        &:hover {
          color: $tf-grey-darker;
        }
      }
    }

    .cityAndCodeContainer {
      display: flex;
      flex-direction: row;

      .cityContainer {
        flex: 2;
        padding-bottom: 0.7rem;
        padding-right: 0.7rem;
      }

      .codeContainer {
        flex: 1;
      }
    }
  }

  .footerContainer {
    width: 100%;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.recapContainer {
  text-align: center;

  .title {
    padding-bottom: 1rem;
    font-family: $tf-font-title;
    font-weight: $tf-semibold;
    font-size: 1.2rem;
  }

  .book {
    font-weight: $tf-book;
  }

  .withoutPromo {
    text-decoration: line-through;
    color: $tf-grey-mid-dark;
  }

  .withPromo {
    padding-left: 0.5rem;
    font-weight: $tf-book;
  }

  .promoContainer {
    margin-top: $tf-padding;

    .promoApplied {
      font-weight: $tf-semibold;
    }
  }
}

.errorContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error {
    color: $tf-red;
    text-align: center;
    white-space: pre-wrap;
  }
}

.successMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    font-weight: $tf-semibold;
    font-size: 1.2rem;
    @include desktop {
      font-size: 1.5rem;
    }
    padding-bottom: 2rem;
  }

  .body {
    white-space: pre-wrap;
    padding-bottom: 1rem;
  }

  .link {
    @include owardLink();
  }

  .ps {
    margin-top: 1.5rem;
    font-size: 0.9rem;

    .link {
      @include owardLink();
    }
  }
}
