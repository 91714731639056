@import "../../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/generic.sass";
@import "~bulma/sass/utilities/initial-variables.sass";

.badgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .badge {
    background-color: $tf-red;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      color: $tf-white;
      font-weight: $tf-book;
      font-size: 0.8rem;
    }
  }

  .widerBadge {
    width: 1.3rem;
  }
}

.absolute {
  position: absolute;
  top: 0.1rem;
  right: 0rem;
}

.static {
  position: static;
  margin-left: 0.5rem;
}
