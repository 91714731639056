@import '../../../assets/sass/bulma-customization.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/utilities/mixins.sass';

.mainContainer {
  z-index: 1000;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $tf-grey-dark;
  opacity: 0.5;

  .loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
