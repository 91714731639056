@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .emptyContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .paymentMethodContainer {
    margin-bottom: 1rem;
    width: 100%;
    border: thin solid $tf-grey-light;
    border-radius: $tf-card-border-radius;

    .upContainer {
      display: flex;
      padding: 1rem;

      .leftContainer {
        flex: 3;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .brandIcon {
          margin-right: 0.5rem;
        }

        .expired {
          color: $tf-red;
          font-weight: $tf-book;
        }

        .number {
          font-weight: $tf-semibold;
        }

        .creationDate {
          padding-top: 0.5rem;
          font-size: 0.8rem;
          color: $tf-grey-mid;
        }
      }

      .rightContainer {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        .buttonsIconContainer {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .buttonsIconCircleContainer {
            width: 40%;
            padding-bottom: 40%;
            position: relative;
            cursor: pointer;

            border-radius: 10rem;
            &:hover {
              background-color: $tf-grey-lighter;
            }

            .buttonsIcon {
              position: absolute;
              width: 50%;
              height: 50%;
              left: 25%;
              top: 25%;
            }

            .red {
              fill: $tf-red;
            }
          }
        }
      }
    }

    .footerContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.5rem;
      border-radius: 0 0 $tf-card-border-radius $tf-card-border-radius;
    }

    .defaultContainer {
      border-top: thin solid $tf-grey-light;

      .default {
        font-weight: $tf-semibold;
      }
    }

    .expiredContainer {
      .expired {
        color: $tf-red;
        font-weight: $tf-semibold;
      }
    }
  }

  .savedOnStripe {
    margin-top: 1rem;
    text-align: center;
    color: $tf-grey-mid-dark;
    font-size: 0.8rem;

    .icon {
      margin: 0 0.2rem;
      font-size: 1.2rem;
      cursor: pointer;
      color: $tf-grey-dark;

      &:hover {
        color: $tf-grey-darker;
      }
    }
  }
}
