@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.categoryTitle {
  text-align: center;
  font-weight: $tf-semibold;
  font-size: 1.3rem;
  padding-bottom: 1rem;
}

.desc {
  @include owardDescription();
  white-space: pre-wrap;
}

.deleteButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
