@import "../../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.subtitleContainer {
  width: 100%;
  @include desktop() {
    padding: 0 $tf-padding;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;

  .subtitle {
    padding-top: 1rem;

    color: $tf-grey-mid-dark;

    font-size: 0.9rem;
    @include desktop() {
      font-size: 1rem;
    }
  }
}

.headerContainer {
  display: flex;
  padding: 0 0.5rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: -1rem;
  margin-bottom: 1rem;
}

.mainCtaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alreadyActiveContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .alreadyActive {
    font-weight: $tf-semibold;
    margin-bottom: $tf-padding;
  }
}

.incompleteContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .incomplete {
    font-weight: $tf-book;
    margin-bottom: $tf-padding;
  }
}

.pastDueContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .pastDue {
    font-weight: $tf-book;
    margin-bottom: $tf-padding;
  }
}
