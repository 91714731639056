@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$avatar-size: 10rem;

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: 2rem;

  .contentContainer {
    // 3 * artworks container + 8 * grid-gap
    width: calc(((#{$tf-card-width} - 2 * #{$tf-card-padding-h}) * 3) + 8rem);

    padding: 1rem 0;
    @include desktop() {
      padding: 3rem;
    }

    .bio {
      white-space: pre-wrap;
    }

    .headerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 0 0.5rem;
      @include desktop() {
        padding: 0;
      }

      .avatarContainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $avatar-size;
        height: $avatar-size;

        .avatar {
          width: $avatar-size;
          height: $avatar-size;
          object-fit: cover;
          border-radius: calc(#{$avatar-size} / 2);
          border: thin solid $tf-grey-light;
        }

        .initialsContainer {
          width: $avatar-size;
          height: $avatar-size;
          border-radius: calc(#{$avatar-size} / 2);
          border: thin solid $tf-grey-light;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $tf-grey-mid;

          .initials {
            overflow: hidden;
            font-family: $tf-font-title;
            font-weight: $tf-bold;
            font-size: 1.2rem;
            text-overflow: ellipsis;
            color: $tf-white;
          }
        }
      }

      .nameAndBadgeContainer {
        max-width: 100%;
        position: relative;
        display: inline-block;
        margin: 0 1.5rem; // For badge to be seen if name too long

        .name {
          font-family: $tf-font-title;
          font-weight: $tf-semibold;
          font-size: 1.7rem;
          @include desktop() {
            font-size: 2rem;
          }
        }

        .badgeCheckIconContainer {
          height: 1.2rem;
          width: 1.2rem;
          top: 0.9rem;
          right: -1.7rem;
          position: absolute;

          .icon {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
      }

      .job {
        padding: calc(#{$tf-padding} - 0.5rem) 0 $tf-padding 0;
        font-weight: $tf-semibold;
        font-size: 1.2rem;
      }

      .locationContainer {
        margin-top: -0.5rem;
        .location {
          color: $tf-grey-mid;
        }
      }

      .bioShortContainer {
        padding-bottom: $tf-padding;
      }

      .agentAssoContainer {
        padding-bottom: $tf-padding;
        .agentAssoIntro {
          font-weight: $tf-book;
        }

        .agentAsso {
          @include owardLink();
        }
      }
    }

    .editMyProfileContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
    }

    .buttonsContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      padding: 0 0.2rem 1rem 0.2rem;
      @include desktop() {
        padding: 0 $tf-padding $tf-padding $tf-padding;
      }

      .iconButtonContainer {
        width: 3rem;
        @include desktop() {
          width: 5rem;
        }
      }
    }

    .infosContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 0.5rem $tf-padding 0.5rem;
      @include desktop() {
        padding: 0 0 $tf-padding 0;
      }
    }

    .additionnalJobsContainer {
      padding-bottom: $tf-padding;

      .additionnalIntroJobs {
        font-weight: $tf-semibold;
      }
    }

    .festivalBroadcasterContainer {
      padding-bottom: $tf-padding;

      .festivalBroadcasterIntro {
        font-weight: $tf-semibold;
      }
    }

    .artworksMainContainer {
      width: 100%;

      .title {
        font-family: $tf-font-title;
        font-weight: $tf-semibold;
        font-size: 1.5rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
      }

      .artworksContainer {
        @include artworkGrid();
      }
    }
  }
}

.goGalleryButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notPublishedMainContainer {
  @mixin headAndFootContainer {
    width: 100%;
    padding: 0.5rem;
    @include desktop() {
      padding: $tf-padding;
    }
  }

  .headerContainer {
    @include headAndFootContainer();

    .title {
      text-align: center;
      font-weight: $tf-semibold;
    }

    .link {
      @include owardLink();
    }

    .buttonsMainContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  .notPublishedPageContainer {
    @include touch() {
      border-top: $tf-border;
      border-bottom: $tf-border;
    }

    @include desktop() {
      border: $tf-border;
      border-radius: $tf-border-radius;
      margin: 0 $tf-padding;
    }
  }

  .footerContainer {
    @include headAndFootContainer();
    padding-top: 0;
  }
}
