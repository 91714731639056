@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - #{$navbar-height});
  background-color: $tf-grey-lighter;
}

.rightContainer {
  width: 100%;
  // margin-left is controled by Javascript in layout-store for sidebar display
  // transition is added in layout-store, to avoid the transition at load
  @include desktop {
    margin-left: $tf-sidebar-desktop-width;
  }
}

.rightContainerSidebarOpened {
  @include desktop {
    margin-left: $tf-sidebar-desktop-width;
  }
}

.rightContainerSidebarClosed {
  @include desktop {
    margin-left: 0;
  }
}

.sidebardMobileClose {
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  top: $navbar-height; // Stay at the top (just under the navbar)
  z-index: 10; // Stay on top
  height: calc(100vh - #{$navbar-height});
  overflow-x: hidden;
  left: 0; //-100vw; // Controled by Javascript (in layout-store)
  width: 100vw; // Controled by Javascript (in layout-store)
  display: none;

  background-color: $tf-grey-darker;
  //opacity: 0;
  //transition: opacity $tf-sidebar-transition-params;
  cursor: pointer;
  &:hover {
    background-color: $tf-grey-dark;
  }

  animation: fadeInFromNone $tf-sidebar-transition-params 1 forwards alternate;
  -webkit-animation: fadeInFromNone $tf-sidebar-transition-params 1 forwards alternate;
  -moz-animation: fadeInFromNone $tf-sidebar-transition-params 1 forwards alternate;
  -o-animation: fadeInFromNone $tf-sidebar-transition-params 1 forwards alternate;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: $tf-sidebar-mobile-close-opacity;
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: $tf-sidebar-mobile-close-opacity;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: $tf-sidebar-mobile-close-opacity;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: $tf-sidebar-mobile-close-opacity;
  }
}

.sidebarContainer {
  position: fixed;
  background-color: $tf-white;
  //box-shadow: $tf-layout-shadow; // Shadow of the sidebar -> removed in current design
  border: thin solid $tf-grey-light;
  z-index: 10; // Stay on top
  top: $navbar-height; // Stay at the top (just under the navbar)
  height: calc(100vh - #{$navbar-height});
  overflow-x: hidden;
  left: calc(-#{$tf-sidebar-mobile-width}); // Controled by Javascript (in layout-store)
  width: $tf-sidebar-mobile-width; // Controled by Javascript (in layout-store)
  @include desktop {
    left: 0; // Controled by Javascript (in layout-store)
    width: $tf-sidebar-desktop-width; // Controled by Javascript (in layout-store)
  }
  // The transition is added by Javascript (after first interraciton on desktop, before on mobile)
}

.sidebarContainerSidebarOpened {
  left: 0;
}

.sidebarContainerSidebarClosed {
  left: -100vw;
  @include desktop {
    left: calc(-#{$tf-sidebar-desktop-width});
  }
}

.bottomBar {
  width: 100%;
  height: $navbar-height;
  background-color: $tf-white;
  position: fixed;
  bottom: -$navbar-height;
  transition: bottom $tf-sidebar-transition-params;
  border-top: thin solid $tf-grey-light;

  z-index: 8;

  display: flex;
  align-items: center;
  justify-content: center;
}
