@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  padding-bottom: 2rem;

  .noInvoiceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .invoiceContainer {
    display: flex;
    padding: 1rem;
    margin-bottom: 1rem;
    border: thin solid $tf-grey-light;
    border-radius: $tf-card-border-radius;

    .leftContainer {
      flex: 3;

      .book {
        font-weight: $tf-book;
      }

      .link {
        @include owardLink();
      }

      .red {
        color: $tf-red;
      }

      .iconSuccess {
        padding-left: 0.5rem;
        color: $tf-green;
      }
    }

    .rightContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .buttonsIconContainer {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .buttonsIconCircleContainer {
          width: 40%;
          padding-bottom: 40%;
          position: relative;
          cursor: pointer;

          border-radius: 10rem;
          &:hover {
            background-color: $tf-grey-lighter;
          }

          .buttonsIcon {
            position: absolute;
            width: 50%;
            height: 50%;
            left: 25%;
            top: 25%;
          }
        }
      }
    }
  }
}
