@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.topBarContainer {
  display: flex;
  padding: 0.3rem;
  @include tablet() {
    padding: 0.5rem;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .text {
    color: white;
    font-weight: $tf-semibold;
    font-size: 0.9rem;

    @include tablet() {
      font-size: 1rem;
    }
  }

  .link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.blueBar {
  background-color: $tf-blue;
}

.redBar {
  background-color: $tf-red;
}

.dontDisplayOnMobile {
  display: none;
  @include desktop() {
    display: flex;
  }
}
