@import "../../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.pageSectionContainer {
  margin: 1rem 0;
  padding: 0.5rem;
  @include desktop() {
    padding: $tf-padding;
    margin: 2rem 0;
  }
}

.pageSectionTitle {
  display: flex;
  justify-content: center;
  color: $tf-grey-dark;
  text-align: center;
  font-weight: $tf-semibold;
  font-family: $tf-font-title;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;

  @include mobile {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.subscriptionCardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 0.5rem;
  @include desktop() {
    padding: $tf-padding;
  }

  .subscriptionCards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    .subscriptionCard {
      height: 100%;
      width: 85%;
      padding: 0.5rem;
      margin: 0.5rem;

      @include tablet() {
        width: 30rem;
        margin: 1.5rem;
        padding: 1rem;
      }

      display: flex;
      justify-content: center;
      flex-direction: column;

      border-radius: $tf-border-radius;
      border: thin solid $tf-grey-light;

      .title {
        font-weight: $tf-semibold;
        font-size: 2.2rem;
        padding-bottom: 0.5rem;
        text-align: center;
      }

      .subtitle {
        font-weight: $tf-book;
        font-size: 1rem;
        padding-bottom: 0.5rem;
        color: $tf-grey-darker;
        text-align: center;
      }

      .price {
        padding: 0.5rem 0;
        font-weight: $tf-book;
        text-align: center;

        .priceAmount {
          font-size: 2rem;
        }
      }

      .included {
        font-weight: $tf-semibold;
      }

      .includedListContainer {
        white-space: pre-wrap;
        margin: 0.5rem 0;
        @include tablet() {
          margin-top: 1.5rem;
        }

        .includedList {
          margin: 0.5rem 0;
        }
      }

      .ctaContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1.5rem;
      }

      .underCta {
        text-align: center;
        font-size: 0.8rem;
        color: $tf-grey-mid-dark;
      }
    }

    .subscriptionCard.blueBorder {
      border: thin solid $tf-blue;
    }
  }
}

:export {
  paymentMethodImageWidth: 6rem;
  paymentMethodImageHeight: 3rem;
}

.paymentMethodMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .paymentMethodContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0.5rem;

    .stripeContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      padding: 0.5rem;
    }

    .sslContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      padding: 0.5rem;
      border-left: $tf-border;
    }

    .imageContainer {
      padding: 0.5rem 0;
      width: 6rem;
      height: 3rem;

      .image {
        object-fit: cover;
      }
    }

    .subtitle {
      font-weight: $tf-semibold;
    }

    .text {
      color: $tf-grey-mid;
      font-size: 0.8rem;
    }
  }
}

.videoEmbedMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: $tf-grey-light;

  .embedContainer {
    display: flex;
    flex-direction: column;

    width: 95vw;
    @include desktop() {
      width: 70vw;
    }
    @include fullhd() {
      width: 60vw;
    }
    @include widescreen() {
      width: 50vw;
    }
  }
}

.mainFaqContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  white-space: pre-wrap;

  .questionMainContainer {
    display: flex;
    justify-content: center;

    .questionContainer {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 0.5rem 1rem;
      @include desktop {
        padding: 1rem 1.5rem;
      }
      border-radius: $tf-border-radius;

      &:hover {
        cursor: pointer;
        background-color: $tf-grey-lighter;
      }
    }
  }
  .textQuestion {
    font-size: $tf-header-subtitle-size;
    font-weight: $tf-book;
  }

  .containerAnswer {
    .textAnswer {
      text-align: center;
      padding: 1rem 10rem;

      @include mobile {
        font-size: 1rem;
        padding: 1rem 1.5rem;
      }
    }
  }

  .breaklinefaq {
    border-bottom: $tf-border;
    margin: 0.2rem 15rem;
    @include mobile {
      margin: 0;
    }
  }

  .angleContainer {
    align-self: center;
    padding-left: 1rem;

    .angle {
      transition: transform $tf-sidebar-accordion-angle-transition-params;
    }
    .angleRotated {
      transform: rotate(90deg);
    }
    &:hover {
      cursor: pointer;
      font-weight: $tf-bold;
    }
  }
}

:export {
  avatarSize: $tf-card-avatar-size;
}

.recommendationsMainContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $tf-grey-light;

  padding: 0.5rem 0;
  @include desktop() {
    padding: $tf-padding 0;
  }

  .recommendationsContainer {
    width: 100%;
    display: flex;
    padding: 0 $tf-padding;
    flex-direction: row;
    overflow: auto;

    .recommendationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 15rem;
      height: 29rem;
      @include desktop() {
        min-width: 21rem;
        height: 21rem;
      }
      margin: 1rem 1rem;
      padding: $tf-padding;
      border-radius: $tf-card-border-radius;
      background-color: $tf-white;
      text-align: center;

      .avatarContainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $tf-card-avatar-size;
        height: $tf-card-avatar-size;

        .avatar {
          width: $tf-card-avatar-size;
          height: $tf-card-avatar-size;
          border-radius: calc(#{$tf-card-avatar-size} / 2);
          border: $tf-border;
          object-fit: cover;
        }
      }

      .textContainer {
        flex: 10;
        width: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          font-weight: $tf-book;
          white-space: pre-wrap;
        }
      }

      .nameContainer {
        flex: 1;

        .name {
          font-style: italic;
        }
      }
    }
  }
}

.bottomCtaButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

$partnerLogoWidth: 3.5rem;
$partnerLogoHeight: 3.5rem;

:export {
  partnerLogoWidth: $partnerLogoWidth;
  partnerLogoHeight: $partnerLogoHeight;
}

.footerMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  @include desktop() {
    padding: $tf-padding;
  }

  background-color: $tf-grey-darker;

  .topContainer {
    width: 100%;
    border-bottom: thin solid $tf-gold;
    padding: $tf-padding 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    @include desktop() {
      flex-direction: row;
    }

    .firstColumn {
      flex: 2;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      text-align: center;
      @include desktop() {
        text-align: left;
      }

      .explanation {
        color: $tf-white;
        font-size: 0.9rem;
        white-space: pre-wrap;
      }

      .brandName {
        font-family: $tf-font-title;
        font-weight: $tf-bold;
        font-size: 1.7rem;
        color: $tf-white;

        .noLetterSpacing {
          letter-spacing: -0.45rem;
        }
      }
    }

    .column {
      flex: 1;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }

    .columnTitle {
      font-weight: $tf-semibold;
      color: $tf-gold;
    }

    .link {
      color: white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .bottomContainer {
    width: 100%;
    display: flex;

    padding: 0.5rem 0;
    align-items: center;
    @include desktop() {
      padding: $tf-padding 0;
      align-items: flex-start;
    }

    .contentContainer {
      @include desktop() {
        width: 20rem;
      }

      .logosContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @include desktop() {
          justify-content: flex-start;
        }

        padding: 0.5rem 0;

        .logoContainer {
          width: $partnerLogoWidth;
          height: $partnerLogoHeight;
          margin-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .logo {
            object-fit: cover;
          }
        }
      }
      .partners {
        font-weight: $tf-book;
        font-size: 0.8rem;
        color: $tf-grey-mid-light;
      }
    }
  }
  .rightsContainer {
    padding-bottom: $tf-padding;
    .rights {
      color: $tf-gold;
      font-weight: $tf-semibold;
    }
  }
}
