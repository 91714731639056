@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

$share-toast-translation: translate(5rem);

:export {
  shareToastTranslation: $share-toast-translation;
}

.backgroundContainer {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $tf-grey-darker;
  @include desktop {
    padding: $artwork-modal-padding-h 0;
  }
}

.notFullWidthContainer {
  $modal-max-width: 1000px;
  float: left;
  bottom: 0;
  left: 0;
  right: 0;
  @include desktop {
    width: $modal-max-width;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  padding: $artwork-modal-padding-h 0;
  @include desktop {
    padding: 0;
  }

  .textContainer {
    padding: $artwork-modal-text-padding-v $artwork-modal-text-padding-mobile-h;
    @include desktop {
      padding: $artwork-modal-text-padding-v $artwork-modal-text-padding-h;
    }
    color: white;
    .bold {
      font-weight: $tf-bold;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .titleAndYearContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        $title-font-size-mobile: 1.5rem;
        $title-font-size-desktop: 2rem;

        .titleAndYear {
          flex: 20;
          font-size: $title-font-size-mobile;
          @include desktop {
            font-size: $title-font-size-desktop;
          }
        }

        .iconContainer {
          position: relative;
          flex: 1;
          height: $title-font-size-mobile;
          min-width: $title-font-size-mobile;
          max-width: $title-font-size-mobile;
          @include desktop {
            height: $title-font-size-desktop;
            min-width: $title-font-size-desktop;
            max-width: $title-font-size-desktop;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0.5rem;

          .iconSvgContainer {
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;

            border-radius: 10rem;

            &:hover {
              background-color: $tf-grey-dark;
            }

            .shareIcon {
              position: absolute;
              width: 60%;
              height: 60%;
              left: 14%;
              top: 20%;
              fill: white;
            }

            .reportIcon {
              position: absolute;
              width: 60%;
              height: 60%;
              left: 20%;
              top: 20%;
              fill: $tf-grey-light;
            }
          }

          .iconSvgContainer.withBorder {
            border: thin solid white;
          }
        }
      }

      @mixin subtitle {
        color: white;
        font-size: 1.2rem;
        @include desktop {
          font-size: 1.3rem;
        }
      }

      .filmType {
        @include subtitle();
      }

      .nameAndJob {
        @include subtitle();

        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      .nameAndJob.deactivated {
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .descriptionContainer {
      padding-top: $artwork-modal-text-padding-v;
      text-align: justify;
      white-space: pre-wrap;
    }

    .directorContainer {
      padding-top: $artwork-modal-text-padding-v;
    }

    .selectionsContainer {
      padding-top: $artwork-modal-text-padding-v;

      .selections {
        padding-left: 1rem;
      }
    }

    .contributorsMainContainer {
      padding-top: $artwork-modal-text-padding-v;
      .contributorsContainer {
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        @include tablet {
          flex-direction: row;
          flex-wrap: wrap;
        }

        .contributorContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 1rem 1rem 0;
          padding: 1rem;
          border: thin solid $tf-grey-mid;
          border-radius: $tf-border-radius;
          cursor: pointer;
          &:hover {
            background-color: $tf-grey-mid-dark;
          }

          .avatarContainer {
            padding-right: 0.5rem;
          }

          .contributorTextContainer {
            .name {
              font-size: 1.2rem;
              font-family: $tf-font-title;
              font-weight: $tf-semibold;
            }
          }
        }

        .contributorContainer.deactivated {
          cursor: default;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .awardedIcon {
      cursor: default;
    }
  }

  .photosContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $artwork-modal-text-padding-v;

    .photoContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
    }
  }

  .errorContainer {
    text-align: center;

    .error {
      font-size: 1.5rem;
      color: white;
    }
  }
}
