@use "sass:math";
@import "../../assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

// All preview stuff are not clickable, and not underlined
.preview {
  &:hover {
    cursor: default !important;
    text-decoration: none !important;
  }
}

.noArtworkContainer {
  width: 100%;
  height: 31rem; // No beautiful...
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: $tf-semibold;

  .max {
    color: $tf-red;
  }
}

.artworkContainer {
  position: relative;
  width: 100%;

  .artworkImageContainer {
    position: relative;
    &:hover {
      cursor: pointer;
    }

    .artworkIconsContainer {
      position: absolute;
      z-index: 2;
      right: $tf-card-artwork-icon-container-offset;
      bottom: 0;

      .artworkIconContainer {
        margin-bottom: $tf-card-artwork-icon-container-offset;
        width: $tf-card-artwork-icon-container-size;
        height: $tf-card-artwork-icon-container-size;
        border-radius: math.div($tf-card-artwork-icon-container-size, 2);
        background-color: $tf-white;
        display: flex;
        align-items: center;
        justify-content: center;

        .svgIconContainer {
          width: 75%;
          height: 75%;
          position: relative;
        }

        .svgIconSmallContainer {
          width: 60%;
          height: 60%;
          position: relative;
        }

        .svgIcon {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0;
        }

        .artworkIcon {
          font-size: $tf-card-artwork-icon-size;
        }
      }
    }

    .artworkDescriptionContainer {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      overflow: hidden; // /!\ overflow auto would provide a weird jerky effect on scroll
      padding: $tf-card-artwork-desc-padding-v $tf-card-artwork-desc-padding-h;

      background: $tf-card-artwork-desc-background;
      opacity: 0;
      transition: opacity $tf-card-artwork-desc-transition-params;

      border-radius: 0;
      @include from-card-not-100vw {
        border-radius: $tf-card-border-radius;
      }

      &:hover {
        opacity: 100;
      }

      .artworkDescription {
        margin: auto;
        color: $tf-white;
        font-size: $tf-card-artwork-desc-text-size;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }

      .nameAndJob {
        font-weight: $tf-bold;
      }

      .seeArtwork {
        font-weight: $tf-semibold;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @mixin artwork {
      vertical-align: middle; // Remove the gap under the image. See https://stackoverflow.com/questions/31444891/mystery-white-space-underneath-image-tag/31445364#31445364
      object-fit: cover;
      width: 100vw;
      border-radius: 0;

      @include from-card-not-100vw {
        width: $tf-card-artwork-w;
        border-radius: $tf-card-border-radius;
      }
    }

    .artwork {
      &__large {
        @include artwork();

        height: calc(100vw * #{$tf-card-artwork-large-ratio});
        @include from-card-not-100vw {
          height: $tf-card-artwork-large-h;
        }
      }

      &__mediumLarge {
        @include artwork();

        height: calc(100vw * #{$tf-card-artwork-medium-large-ratio});
        @include from-card-not-100vw {
          height: $tf-card-artwork-medium-large-h;
        }
      }

      &__mediumSmall {
        @include artwork();

        height: calc(100vw * #{$tf-card-artwork-medium-small-ratio});
        @include from-card-not-100vw {
          height: $tf-card-artwork-medium-small-h;
        }
      }

      &__small {
        @include artwork();

        height: calc(100vw * #{$tf-card-artwork-small-ratio});
        @include from-card-not-100vw {
          height: $tf-card-artwork-small-h;
        }
      }
    }
  }

  .noPhotoContainer {
    background-color: $tf-grey-darker;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    flex-wrap: wrap;

    .noPhoto {
      color: $tf-white;
      font-size: 1.5rem;
      font-family: $tf-font-title;
      font-weight: $tf-semibold;
    }
  }

  .artworkInfoContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: $tf-card-artwork-info-line-height;
    padding: $tf-card-artwork-info-pagging-t 0 $tf-card-artwork-info-pagging-b 0;

    .artworkType {
      color: $tf-grey-dark;
      font-size: $tf-card-artwork-type-text-size;
      font-weight: $tf-bold;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .artworkTitle {
      color: $tf-grey-mid-dark;
      font-size: $tf-card-artwork-title-text-size;
      font-weight: $tf-regular;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 94%;
      @include tablet {
        width: 97%;
      }
    }
  }
}

$match-border-size: 5px;
$match-color: rgba($tf-gold, 0.8);
$match-mobile-margin: 1%;

.matchFilterContainer {
  position: absolute;

  left: $match-mobile-margin;
  width: calc(100% - 2 * #{$match-mobile-margin});
  @include tablet {
    left: 0%;
    width: 100%;
  }
  height: 90%;
  bottom: $match-border-size;

  background-color: $match-color;
  border-radius: $tf-card-border-radius;
}

.blockMainContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.92;
  background-color: $tf-grey-darker;
  z-index: 6; // Stay on top

  @include from-card-not-100vw {
    border-radius: $tf-card-border-radius;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text {
      color: $tf-grey-light;
      text-align: center;
    }
  }
}
