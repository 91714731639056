@import "@assets/sass/bulma-customization.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/utilities/mixins.sass";

.mainContainer {
  width: 100%;
  background-color: $tf-grey-lighter;

  /* Padding helper : top | right | bottom | left */
  padding: 1rem 1rem 0.875rem 1rem;
  @include tablet {
    padding: 1rem 1.25rem 0 1.25rem;
  }
  @include desktop {
    padding: 1rem 2.25rem 0 2.25rem;
  }
  @include widescreen {
    padding: 1rem 2.25rem 0 2.25rem;
  }
  @include fullhd {
    padding: 1rem 3rem 0 2.25rem;
  }

  display: flex;
  flex-direction: column;
}

.topContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titlesContainer {
    text-align: center;
    padding: $tf-padding;
    color: $tf-grey-darker;

    .title {
      font-weight: $tf-bold;
      font-size: $tf-header-title-size-mobile;

      @include desktop {
        font-size: $tf-header-title-size;
      }
    }

    .subtitle {
      color: $tf-grey-mid-dark;
      white-space: pre-wrap;
      font-weight: $tf-semibold;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.useOwardContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  @include desktop {
    margin-top: 3rem;
  }

  .useOward {
    color: $tf-grey-dark;
    font-weight: $tf-semibold;
    font-size: 1.2rem;
    text-align: center;

    @include desktop {
      font-size: 1.5rem;
    }
  }
}
